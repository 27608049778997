/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Error } from "../form-fields/Error";
import { ROLES, userIs } from "./../../constants/Roles";
import Topic from "./Topic";
import CreateTopic from "./topic/create/Create";
import EditTopic from "./topic/edit/Edit";

const TopicsList = ({ topics, error, setArchived, user, authors }) => {
  const [showCreateTopic, setShowCreateTopic] = useState(false);
  const [topic, setTopic] = useState(null);
  const [showEditTopic, setShowEditTopic] = useState(false);
  const hideCreateTopicModal = () => {
    setShowCreateTopic(false);
  };

  const openCreateTopicModal = () => {
    setShowCreateTopic(true);
  };

  const hideEditTopicModal = () => {
    setTopic(null);
    setShowEditTopic(false);
  };

  const openEditTopicModal = (topic) => {
    setTopic(topic);
    setShowEditTopic(true);
  };

  const onArchive = (e) => {
    setArchived(e.target.checked);
  };

  if (!Array.isArray(topics)) {
    topics = [];
  }
  const topicsList =
    topics &&
    topics.map((tp) => (
      <Topic openEditModal={openEditTopicModal} key={tp.id} topic={tp} />
    ));

  return (
    <>
      <h1>Topics</h1>
      <Error error={error} />
      {userIs(ROLES.ADMIN) ? (
        <div className="d-flex my-3 gap-4 justify-content-end">
          <div className="d-flex gap-2 pt-2">
            <input
              id="archive"
              className="form-check-input"
              type="checkbox"
              onChange={onArchive}
            />
            <label htmlFor="archive">Show Archived</label>
          </div>
          <button className="btn btn-primary" onClick={openCreateTopicModal}>
            Add New Topic
          </button>
        </div>
      ) : (
        ""
      )}
      <div className="my-3 list-group">{topicsList}</div>
      {userIs(ROLES.ADMIN) ? (
        <>
          <CreateTopic
            show={showCreateTopic}
            handleClose={hideCreateTopicModal}
          />
          <EditTopic
            show={showEditTopic}
            invalidate={"topics"}
            topic={topic}
            authors={authors}
            handleClose={hideEditTopicModal}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default TopicsList;
