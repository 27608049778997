/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Priority } from "./../../../../constants/Priority";
import { TOPIC_STATUS } from "./../../../../constants/TopicStatus";
import { MultiSelect } from "./../../../form-fields/MultiSelect";
import { MySelect } from "./../../../form-fields/Select";
import { MyTextArea } from "./../../../form-fields/TextArea";
import { MyTextInput } from "./../../../form-fields/TextInput";
const EditTopicForm = ({
  topic,
  handleSubmit,
  handleClose,
  isCreating,
  user,
  authors,
}) => {
  const options = TOPIC_STATUS.map((option) => (
    <option key={option.value} value={option.value}>
      {" "}
      {option.label}
    </option>
  ));

  const priorityOptions = Priority.map((option) => (
    <option key={option.value} value={option.value}>
      {" "}
      {option.label}
    </option>
  ));

  const allAuthors = authors?.map((i) => {
    return {
      label: i.name,
      value: i.id,
    };
  });

  return (
    <div>
      <Formik
        initialValues={{
          title: topic?.title,
          description: topic?.description,
          status: topic?.status,
          priority: topic?.priority,
          assigned_writers: topic?.assigned_writers
            ? allAuthors.filter((i) => topic.assigned_writers.includes(i.value))
            : [],
        }}
        validationSchema={Yup.object({
          title: Yup.string().required(),
          description: Yup.string().required(),
          status: Yup.string().required(),
        })}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(formikProps) => (
          <Form>
            <MyTextInput
              name="title"
              label="Title"
              placeholder="Topic title"
              className="form-control"
            />
            <MyTextArea
              name="description"
              label="Description"
              placeholder="Topic description"
              className="form-control"
            />
            <MySelect
              name="status"
              placeholder="Status"
              className="form-control"
              label="Status"
            >
              {options}{" "}
            </MySelect>

            <MySelect
              name="priority"
              placeholder="Status"
              className="form-control"
              label="Priority."
            >
              {priorityOptions}
            </MySelect>

            <MultiSelect
              name="assigned_writers"
              label="Assigned writers"
              options={allAuthors}
            ></MultiSelect>
            <div className="d-flex justify-content-between mt-3">
              <button
                type="button"
                className="btn btn-outline-secondary px-5"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                disabled={
                  formikProps.isSubmitting || !formikProps.isValid || isCreating
                }
                className="btn btn-outline-primary px-5"
                type="submit"
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditTopicForm;
