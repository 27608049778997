/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}`;

const ErrorService = {
  create: (formData) => {
    return axios.post(`${apiUrl}/error`, formData);
  },
};

export default ErrorService;
