/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { recoverPassword } from "../../../states/actions/userAction";
import { Error } from "../../form-fields/Error";
import CreatePasswordForm from "./CreatePasswordForm";

const RecoverPassword = () => {
  let query = useQuery();
  const [error, setError] = useState(null);
  const history = useHistory();
  const resetKey = query.get("resetKey");

  const recoverUserPassword = async (formData) => {
    formData.resetKey = resetKey;
    try {
      await recoverPassword({ ...formData });
      history.push("/internal/login");
    } catch (e) {
      setError({ message: "Invalid Credentials" });
    }
  };

  return (
    <div className="col col-md-4 col-lg-3 vh-100 mx-5 mx-md-auto  d-flex flex-column justify-content-center">
      <h1>Recover Password</h1>
      <Error erorr={error} />
      <CreatePasswordForm handleSubmit={recoverUserPassword} btnText="Reset" />
    </div>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default RecoverPassword;
