/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ROLES, userIs } from "../../../constants/Roles";
import { MySelect } from "../../form-fields/Select";

const UpdateCollectionForm = ({
  story,
  handleClose,
  handleSubmit,
  isLoading,
  topics,
}) => {
  const options = topics.map((i) => (
    <option value={i.id} label={i.title}></option>
  ));

  return (
    <div className="col-12 col-md-12">
      <Formik
        initialValues={{
          topic: story.topic?._path.segments[1],
        }}
        validationSchema={Yup.object({
          topic: Yup.string().required(),
        })}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form style={{ width: "100%" }}>
            <div className="" style={{ width: "100%" }}>
              {userIs(ROLES.ADMIN) && (
                <MySelect
                  name="topic"
                  label="topic"
                  placeholder="topic"
                  className="form-control "
                >
                  <option value={""} label="select a topic"></option>
                  {options}
                </MySelect>
              )}
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-outline-primary px-5 mt-3"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-outline-primary px-5 mt-3"
                  disabled={formik.isSubmitting || !formik.isValid || isLoading}
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateCollectionForm;
