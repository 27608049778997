/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Error } from "../components/form-fields/Error";
import AssessmentList from "../components/stories/assesment/FilterView";
import StoriesList from "../components/stories/FilterView";
import {
  PsychologistGraph,
  WritersGraph,
} from "../components/user/profile/ActivityGraph";
import EditUser from "../components/users/edit/Edit";
import Loader from "../components/utils/Loader";
import { ROLES, userIs } from "../constants/Roles";
import { STORY_STATUS_KEY } from "../constants/StoryStatus";
import Helpers from "../utils/helpers";
import UserService from "./../services/UserService";

export default function Profile(props) {
  const [error, setError] = useState(null);
  const [author, setAuthor] = useState([]);
  const [showEditUser, setShowEditUser] = useState(false);
  const fetchAll = ({ queryKey }) => {
    const { id } = queryKey[1];
    return UserService.fetchUser(id);
  };

  const { refetch, isLoading, isFetching } = useQuery(
    ["author", { id: props.match.params.id }],
    fetchAll,
    {
      onSuccess: (response) => {
        setAuthor(response.data);
      },
      onError: (error) => {
        setError(Helpers.errorCreator(error));
      },
    }
  );

  const onUpdate = async (formData) => {
    try {
      const { role, status, name, externalUrl, note, restricted } = formData;
      await UserService.update(author.id, {
        role: role,
        status: status,
        name: name,
        externalUrl: externalUrl,
        note: note,
        restricted: restricted,
      });
      refetch();
    } catch (error) {
      setError(Helpers.errorCreator(error));
    } finally {
      hideEditUserModal();
    }
  };

  const showEditUserModal = (user) => {
    setShowEditUser(true);
  };

  const hideEditUserModal = () => {
    setShowEditUser(false);
  };

  return (
    <>
      <Error error={error} />
      <Loader show={isLoading || isFetching} />
      <EditUser
        show={showEditUser}
        handleClose={hideEditUserModal}
        user={author}
        onUpdate={onUpdate}
      />

      <div className="container mt-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10">
            <div className="card p-3 py-4">
              <div className="text-center mt-3">
                <h5 className="mt-2 mb-1">{author?.name}</h5>
                {userIs(ROLES.ADMIN) && (
                  <>
                    <div className="mb-2">{author?.email}</div>
                    <span className="bg-secondary p-1 px-4 rounded text-white mt-2">
                      {author?.status}
                    </span>
                    {author?.externalUrl && (
                      <div className="mt-2">
                        <a
                          href={author.externalUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {author.externalUrl}
                        </a>
                      </div>
                    )}
                    {author?.note && (
                      <div className=" mt-2">{author?.note}</div>
                    )}
                    <div>
                      <button
                        className="btn btn-outline-primary mt-4 ps-4 pe-4"
                        onClick={showEditUserModal}
                      >
                        Edit
                      </button>
                    </div>
                  </>
                )}
                <div style={{ width: "100%" }}>
                  {author.role === ROLES.PSYCHOLOGIST && (
                    <PsychologistGraph values={author.activity_stats} />
                  )}
                  {author.role === ROLES.WRITER && (
                    <WritersGraph values={author.activity_stats} />
                  )}
                </div>
                {userIs(ROLES.ADMIN) && (
                  <>
                    <div className="d-flex justify-content-around pb-2 mb-2 mt-4">
                      <Link
                        to={`/internal/${
                          author.role === ROLES.PSYCHOLOGIST
                            ? "assessments"
                            : "stories"
                        }?author=${author.id}&status=${
                          STORY_STATUS_KEY.review
                        }`}
                      >
                        Review :{" "}
                        {author.role === ROLES.PSYCHOLOGIST
                          ? author.assessmentStats?.[STORY_STATUS_KEY.review] ||
                            0
                          : author.storyStats?.[STORY_STATUS_KEY.review] || 0}
                      </Link>
                      <Link
                        to={`/internal/${
                          author.role === ROLES.PSYCHOLOGIST
                            ? "assessments"
                            : "stories"
                        }?author=${author.id}&status=${
                          STORY_STATUS_KEY.rework
                        }`}
                      >
                        Rework :{" "}
                        {author.role === ROLES.PSYCHOLOGIST
                          ? author.assessmentStats?.[STORY_STATUS_KEY.rework] ||
                            0
                          : author.storyStats?.[STORY_STATUS_KEY.rework] || 0}
                      </Link>
                      <Link
                        to={`/internal/${
                          author.role === ROLES.PSYCHOLOGIST
                            ? "assessments"
                            : "stories"
                        }?author=${author.id}&status=${
                          STORY_STATUS_KEY.finished
                        }`}
                      >
                        Finished :{" "}
                        {author.role === ROLES.PSYCHOLOGIST
                          ? author.assessmentStats?.[
                              STORY_STATUS_KEY.finished
                            ] || 0
                          : author.storyStats?.[STORY_STATUS_KEY.finished] || 0}
                      </Link>
                      <Link
                        to={`/internal/${
                          author.role === ROLES.PSYCHOLOGIST
                            ? "assessments"
                            : "stories"
                        }?author=${author.id}&status=${
                          STORY_STATUS_KEY.abandoned
                        }`}
                      >
                        Abandoned :{" "}
                        {author.role === ROLES.PSYCHOLOGIST
                          ? author.assessmentStats?.[
                              STORY_STATUS_KEY.abandoned
                            ] || 0
                          : author.storyStats?.[STORY_STATUS_KEY.abandoned] ||
                            0}
                      </Link>
                      {author.role === ROLES.PSYCHOLOGIST && (
                        <Link
                          to={`/internal/assessments?author=${author.id}&isSelected=true`}
                        >
                          Selected : {author.assessmentStats?.["Selected"] || 0}
                        </Link>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {userIs(ROLES.ADMIN) && author.role == ROLES.PSYCHOLOGIST && (
            <div className="col-md-10 mt-4">
              <h2>Assessments</h2>
              <AssessmentList author={props.match.params.id} />
            </div>
          )}

          {userIs(ROLES.ADMIN) && author.role == ROLES.WRITER && (
            <div className="col-md-10 mt-4">
              <h2>Stories</h2>
              <StoriesList author={props.match.params.id} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
