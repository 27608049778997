/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { ROLES, userIs } from "../../../constants/Roles";
import { MyTextArea } from "../../form-fields/TextArea";
import { MyTextInput } from "../../form-fields/TextInput";
const KeepStatusButton = ({ formik, isLoading }) => {
  const { values, submitForm } = useFormikContext();
  const submitFormWithStatus = () => {
    values["keepStatus"] = true;
    submitForm();
  };

  return (
    <button
      onClick={submitFormWithStatus}
      disabled={formik.isSubmitting || !formik.isValid || isLoading}
      className="btn btn-outline-primary px-5 mt-3 me-2"
    >
      Keep status and submit
    </button>
  );
};
const UpdateStoryForm = ({ story, handleClose, handleSubmit, isLoading }) => {
  return (
    <div className="col-12 col-md-12">
      <Formik
        initialValues={{
          title: story?.title,
          body: story?.body,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required(),
          body: Yup.string().required(),
        })}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form style={{ width: "100%" }}>
            <div className="" style={{ width: "100%" }}>
              <MyTextInput
                name="title"
                label="Story title"
                plasceholder="Story title"
                className="form-control"
              />
              <MyTextArea
                name="body"
                label="Story body"
                rows={5}
                plasceholder="Write content"
                className="form-control"
                style={{ width: "100%" }}
              />
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-outline-primary px-5 mt-3"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <div>
                  {userIs(ROLES.ADMIN) && (
                    <KeepStatusButton formik={formik} isLoading={isLoading} />
                  )}
                  <button
                    type={"submit"}
                    className="btn btn-outline-primary px-5 mt-3"
                    disabled={
                      formik.isSubmitting || !formik.isValid || isLoading
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateStoryForm;
