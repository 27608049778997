/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import CollectionService from "../../../../services/CollectionService";
import Helpers from "../../../../utils/helpers";
import { Error } from "../../../form-fields/Error";
import EditCollectionForm from "./EditForm";

const EditCollection = ({
  show,
  collection,
  handleClose,
  invalidate,
  topics,
}) => {
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => CollectionService.update(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(invalidate);
      handleClose();
    },
    onError: (error) => {
      setError(Helpers.errorCreator(error));
    },
  });

  const handleSubmit = (formData) => {
    const data = { ...formData };
    mutation.mutate({ id: collection.id, data: data });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit collection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} />
        <EditCollectionForm
          topics={topics}
          collection={collection}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditCollection;
