/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import UserService from "../../services/UserService";
import { Error } from "../form-fields/Error";
import Loader from "../utils/Loader";
import { ROLES } from "./../../constants/Roles";
import { updateUser } from "./../../states/actions/manageUsersAction";
import CreateUser from "./create/Create";
import EditUser from "./edit/Edit";
const UserList = ({ users, refetch }) => {
  const [showEditUser, setShowEditUser] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const showEditUserModal = (user) => {
    setUser(user);
    setShowEditUser(true);
  };

  const hideEditUserModal = () => {
    setShowEditUser(false);
    setUser(null);
  };

  const hideCreateUserModal = () => {
    setShowCreateUser(false);
  };
  const onUpdate = async (formData) => {
    const { role, status, name, note, externalUrl, restricted } = formData;

    await dispatch(
      updateUser(user.id, {
        role: role,
        status: status,
        name: name,
        note: note,
        externalUrl: externalUrl,
        restricted: role === ROLES.PSYCHOLOGIST ? restricted : false,
      })
    );
    refetch();
    hideEditUserModal();
  };

  const onRandomPass = (id) => {
    setShowLoader(true);
    UserService.randomPass(id)
      .then(() => {
        setShowLoader(false);
        alert(
          "this user has been deactivated , and password reset link is sent on mail"
        );
      })
      .catch(() => {
        setShowLoader(false);
      });
  };
  const { isLoading, payload = [], error } = users;

  const usersList =
    !isLoading && !error
      ? payload.map((user) => (
          <tr key={user.id}>
            <td>
              <Link
                to={`/internal/profile/${user.id}`}
                style={{ color: user.status === "HIDDEN" ? "grey" : undefined }}
              >
                {user.status === "ACTIVE" ? (
                  user.name + (user.restricted ? " ✋" : "")
                ) : (
                  <strike>{user.name}</strike>
                )}
              </Link>
            </td>
            <td>{user.email}</td>
            <td>{user.role}</td>
            <td>{user.note}</td>
            <td>
              <center>
                {user.externalUrl && (
                  <a
                    target="_blank"
                    href={user.externalUrl}
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <span> 🔗</span>
                  </a>
                )}
              </center>
            </td>
            <td>
              {" "}
              <div className="d-flex justify-content-around">
                {user.activationlink && (
                  <CopyToClipboard text={user.activationlink}>
                    <button className="btn btn-outline-primary">
                      Copy activation link
                    </button>
                  </CopyToClipboard>
                )}
                <button
                  className="btn btn-outline-primary"
                  onClick={() => onRandomPass(user.id)}
                >
                  Randomize password
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => showEditUserModal(user)}
                >
                  Edit
                </button>
              </div>
            </td>
          </tr>
        ))
      : [];

  const table = (
    <table className="table table-bordered table-striped table-hover">
      <thead>
        <tr key="header">
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Note</th>
          <th>External Profile</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>{usersList}</tbody>
    </table>
  );

  const editUserModal = (
    <EditUser
      onUpdate={onUpdate}
      show={showEditUser}
      handleClose={hideEditUserModal}
      user={user}
    />
  );

  return (
    <>
      <Loader show={showLoader} />
      <Error className="my-5" error={error} />
      <div className="d-flex justify-content-between my-3">
        <h3>Users </h3>{" "}
        <button
          className="btn btn-primary"
          onClick={() => setShowCreateUser(true)}
        >
          Create new user
        </button>
      </div>
      {table}
      {user ? editUserModal : ""}
      <CreateUser
        show={showCreateUser}
        handleClose={hideCreateUserModal}
        refetch={refetch}
      />
    </>
  );
};

export default UserList;
