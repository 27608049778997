/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Link } from "react-router-dom";

import { Fragment, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { STORY_STATUS_KEY } from "../../../constants/StoryStatus";
import AssesmentService from "../../../services/AssesmentService";
import StoryService from "../../../services/StoryService";
import Helpers from "../../../utils/helpers";
import { Error } from "../../form-fields/Error";
import Loader from "../../utils/Loader";
const queryString = require("query-string");
const View = (props) => {
  const [story, setStory] = useState(null);
  const [error, setError] = useState();
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentStory, setCurrentStory] = useState("");
  const queryClient = useQueryClient();
  const history = useHistory();
  let qry = queryString.parse(history.location.search);
  const handleError = (error) => {
    setError(Helpers.errorCreator(error));
  };

  const fetchStory = () => {
    return StoryService.fetchActive({
      selectassesment: "true",
      limit: 1,
      startAfter: currentStory,
    });
  };

  const { isLoading, isFetching } = useQuery(
    ["stories", currentStory],
    fetchStory,
    {
      onSuccess: (response) => {
        if (!response.data.stories.length)
          if (qry?.isFused) {
            return props.history.push(
              "/internal/focused-assessment?isFused=true"
            );
          } else {
            return props.history.push("/internal");
          }

        setStory(response.data.stories[0]);
        setStats(response.data?.allStats?.stats);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const selectAssesment = (id) => {
    setLoading(true);
    return AssesmentService.selectAssesment(id)
      .then(() => {
        queryClient.invalidateQueries(["stories", currentStory]);
      })
      .finally(() => setLoading(false));
  };
  return (
    <Fragment>
      <Loader show={isLoading || isFetching} />
      <div className="container mt-5">
        <Error error={error} />
        <div className="container my-5 d-flex justify-content-between">
          <h1>Select Assessment</h1>
          <button
            className="btn btn-primary ms-4 ps-4 pe-4"
            onClick={() => setCurrentStory(story.id)}
          >
            Skip
          </button>
        </div>
        {story ? (
          <div className="card bg-light  p-4 border rounded">
            <div
              className="details"
              style={{ display: "flex", padding: "5px 20px" }}
            >
              <Link
                className="text-decoration-none"
                to={`/internal/stories/${story.id}`}
              >
                <h4>{story.title}</h4>
              </Link>
              <span
                className="text-bold small text-secondary text-link"
                style={{ marginLeft: "15px", marginTop: "6px" }}
              >
                {Helpers.convertTime(story.updated_at)}
              </span>
              {story.author ? (
                <span style={{ marginLeft: "15px", marginTop: "8px" }}>
                  <h6>
                    <Link
                      className="text-decoration-none"
                      to={`/internal/profile/${story.author.id}`}
                    >
                      {` ${story.author.name}`}
                    </Link>
                  </h6>
                </span>
              ) : (
                ""
              )}
            </div>

            <h6 style={{ padding: "0px 20px" }}>
              on
              <Link
                className="text-decoration-none"
                to={`/internal/topics/${story.topic.id}`}
              >
                {` ${story?.topic?.title}`}
              </Link>
            </h6>

            <p style={{ padding: "0px 20px" }}>
              {Helpers.convertMultiLine(story.body)}
            </p>

            {story.assesments.map((i) => {
              return (
                <div className="card   p-4 border rounded">
                  <h6 className="d-flex">
                    <u>
                      <Link
                        className="text-decoration-none"
                        to={`/internal/stories/${story.id}/assesments/${i.id}`}
                      >
                        Assessment by {` ${i.author.name}`}
                      </Link>
                    </u>
                    <div
                      style={{ height: 28 }}
                      className="status ms-2 text-lowercase border-2 text-info text-body border px-2 rounded-1 border-info bg-white"
                    >
                      {i.status}
                    </div>

                    {story?.selected_assessment?._path?.segments?.[1] ===
                    i.id ? (
                      <span className="ms-4 text-success border border-success ps-2 pe-2">
                        selected assessment
                      </span>
                    ) : (
                      <>
                        {i.status === STORY_STATUS_KEY.finished && (
                          <button
                            className="btn btn-primary ms-4"
                            onClick={() => selectAssesment(i.id)}
                          >
                            Select assessment
                          </button>
                        )}
                      </>
                    )}
                  </h6>

                  <h6>Correct variant:</h6>
                  <span>{i.correctVariant}</span>

                  <hr />

                  <h6>Misdirection variant 1:</h6>
                  <span>{i.misdirectionVariant1}</span>

                  <hr />

                  <h6>Misdirection variant 2:</h6>
                  <span>{i.misdirectionVariant2}</span>

                  <hr />

                  <h6>Explanation:</h6>
                  <span>{i.explanation}</span>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default View;
