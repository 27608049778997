/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { useQuery } from "react-query";
import CollectionList from "../components/collections/List";
import Loader from "../components/utils/Loader";
import CollectionService from "../services/CollectionService";
import TopicService from "../services/TopicService";
import Helpers from "../utils/helpers";

const Collections = () => {
  const [collections, setCollections] = useState([]);
  const [topics, setTopics] = useState([]);
  const [error, setError] = useState(null);

  const fetchAll = () => {
    return CollectionService.fetchAll();
  };

  const { isLoading, isFetching } = useQuery(["collections"], fetchAll, {
    onSuccess: (response) => {
      setCollections(response.data);
    },
    onError: (error) => {
      setError(Helpers.errorCreator(error));
    },
  });
  const fetchAllTopic = ({}) => {
    return TopicService.fetchAll();
  };

  useQuery(["topics"], fetchAllTopic, {
    onSuccess: (response) => {
      setTopics(response.data);
    },
    onError: (error) => {
      setError(Helpers.errorCreator(error));
    },
  });

  return (
    <>
      <Loader show={isLoading || isFetching} />
      <div className="container my-5">
        <CollectionList
          collections={collections}
          error={error}
          topics={topics}
        />
      </div>{" "}
    </>
  );
};

export default Collections;
