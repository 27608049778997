/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}`;

const CommentService = {
  getCommentsByStory: (storyId) => {
    return axios.get(`${apiUrl}/story/storyId/comments`, {
      params: {
        storyId: storyId,
      },
    });
  },
  getCommentsByAssesment: (assesmentd) => {
    return axios.get(`${apiUrl}/comment/assesment/${assesmentd}`);
  },
  create: (formData) => {
    return axios.post(`${apiUrl}/comments`, formData);
  },
};

export default CommentService;
