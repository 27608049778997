/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
const Loader = ({ show }) => {
  const spinner = (
    <div
      className="position-absolute vw-100 vh-100 d-flex flex-column justify-content-center align-items-center"
      style={{ backgroundColor: "rgb(247 245 245 / 50%)", zIndex: 100 }}
    >
      <div className="spinner-border text-primary">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return show ? spinner : null;
};

export default Loader;
