/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AssessmentList from "../components/stories/assesment/FilterView";
import StoryList from "../components/stories/FilterView";
import { ROLES, userIs } from "../constants/Roles";
import { STORY_STATUS_KEY } from "../constants/StoryStatus";
import MiscService from "../services/MiscService";
import UserService from "../services/UserService";
import Helpers from "../utils/helpers";

const user = Helpers.getUser();
const Home = ({ history }) => {
  const [stats, setStats] = useState({});
  const [assessmentStats, setAssessmentStats] = useState({});
  useEffect(() => {
    if (userIs(ROLES.ADMIN)) {
      MiscService.getAllStats().then((res) => {
        setStats(res.data.statsDoc.stats);
        setAssessmentStats({
          ...res.data.assesmentDoc.stats,
          select_assessment: res.data.assesmentDoc.select_assessment,
        });
      });
    } else if (user?.role === ROLES.WRITER)
      UserService.fetchUser(user.id).then((res) => {
        setStats(res.data.storyStats);
      });
  }, []);

  const isSupportedAccount = [
    ROLES.ADMIN,
    ROLES.WRITER,
    ROLES.PSYCHOLOGIST,
  ].includes(user?.role);
  if (!isSupportedAccount) {
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center mt-4"
          style={{ height: 400 }}
        >
          <h5 className="card-title m-0">
            Your account is not associated with any role please contact admin to
            get access.
          </h5>
        </div>
      </>
    );
  }
  return (
    <div className="container my-5">
      {userIs(ROLES.ADMIN) && (
        <>
          <div className="d-flex justify-content-start">
            <button
              className="btn btn-primary"
              onClick={() => history.push("/internal/focusedstories")}
            >
              Review {stats?.[STORY_STATUS_KEY.review]} stories
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={() => history.push("/internal/focused-assessment")}
            >
              Review {assessmentStats?.[STORY_STATUS_KEY.review]} assessments
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={() => history.push("/internal/select-assessment")}
            >
              Select {assessmentStats["select_assessment"]} assessments
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={() =>
                history.push(
                  assessmentStats["select_assessment"]
                    ? "/internal/select-assessment?isFused=true"
                    : assessmentStats?.[STORY_STATUS_KEY.review]
                    ? "/internal/focused-assessment?isFused=true"
                    : "/internal/focusedstories"
                )
              }
            >
              Fused focused review
            </button>
          </div>
          <div className="d-flex justify-content-center">
            <a
              href={`https://github.com/TarasMazepa/lyflair/commit/${process.env.REACT_APP_HASH}`}
              target={"_blank"}
              rel="noreferrer"
            >
              {process.env.REACT_APP_BRANCH}/{process.env.REACT_APP_HASH}
              {process.env.REACT_APP_ISDIRTY === "true" ? "+" : ""}
            </a>
          </div>
        </>
      )}
      {user?.role === ROLES.WRITER && (
        <>
          <h2>Total stories stats</h2>
          <div className="d-flex justify-content-around pb-2 mb-2">
            <Link
              to={`/internal/stories?author=${user.id}&status=${STORY_STATUS_KEY.review}`}
            >
              Review : {stats?.[STORY_STATUS_KEY.review] || 0}
            </Link>
            <Link
              to={`/internal/stories?author=${user.id}&status=${STORY_STATUS_KEY.rework}`}
            >
              Rework : {stats?.[STORY_STATUS_KEY.rework] || 0}
            </Link>
            <Link
              to={`/internal/stories?author=${user.id}&status=${STORY_STATUS_KEY.finished}`}
            >
              Finished : {stats?.[STORY_STATUS_KEY.finished] || 0}
            </Link>
            <Link
              to={`/internal/stories?author=${user.id}&status=${STORY_STATUS_KEY.abandoned}`}
            >
              Abandoned : {stats?.[STORY_STATUS_KEY.abandoned] || 0}
            </Link>
          </div>
          <div className="d-flex justify-content-between">
            <h1>Active stories</h1>
          </div>
          <StoryList status={STORY_STATUS_KEY.rework} sortOrder={"asc"} />
        </>
      )}

      {user?.role === ROLES.PSYCHOLOGIST && (
        <>
          <div className="d-flex justify-content-between">
            <h1>Active assessments</h1>
          </div>
          <AssessmentList sortOrder={"asc"} status={STORY_STATUS_KEY.rework} />
        </>
      )}
    </div>
  );
};

export default Home;
