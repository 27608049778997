/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { connect } from "react-redux";
import { updateProfile } from "../../../states/actions/userAction";
import { Error } from "../../form-fields/Error";
import Loader from "../../utils/Loader";
import UpdatePassword from "../password/UpdatePassword";
import UpdateLogin from "./UpdateLogin";
import UpdateName from "./UpdateName";

const Profile = ({ user, error, isLoading, updateProfile }) => {
  return (
    <>
      <Loader show={isLoading} />
      <div className="my-5 col-11 col-md-6 mx-auto">
        <Error error={error} />
        <UpdateName user={user} updateProfile={updateProfile} />
        <UpdateLogin user={user} updateProfile={updateProfile} />
        <UpdatePassword updateProfile={updateProfile} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.payload,
  isLoading: state.user.isLoading,
  error: state.user.error,
});

export default connect(mapStateToProps, { updateProfile })(Profile);
