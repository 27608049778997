/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import CookieConsent from "./components/utils/CookieConcent";
import App from "./containers/App";
import "./index.css";
import {
  addJWTInterceptor,
  errorHandlerInterceptor,
} from "./services/Interceptors";
import { getCurrentUser } from "./states/actions/userAction";
import rootReducer from "./states/reducers/indexReducer";

const middleware = [thunk];
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
const { dispatch } = store;

dispatch(getCurrentUser());

//interceptor to add to for logged-in users to authenticate
addJWTInterceptor();

//interceptor for filtering errors
errorHandlerInterceptor(dispatch);

//redux is being replaced with react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <QueryClientProvider client={queryClient}>
          <CookieConsent />
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
