/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import * as types from "../../constants/ActionTypes";
import UserService from "../../services/UserService";
import { errorActionCreator } from "./errorAction";
//fetch all users

const listUserRequest = () => ({
  type: types.LIST_USERS_REQUEST,
});

const listUsersSucess = (users) => ({
  type: types.LIST_USERS,
  payload: [...users],
});

const listUsersFailure = (errorRs) => ({
  type: types.LIST_USERS_FAILURE,
  payload: {
    ...errorActionCreator(types.LOGIN_USER_FAILURE, errorRs),
  },
});

export const fetchAllUsers = () => (dispatch) => {
  dispatch(listUserRequest());
  UserService.fetchAll().then(
    (res) => {
      dispatch(listUsersSucess(res.data));
    },
    (error) => {
      dispatch(listUsersFailure({ error: error.response }));
    }
  );
};

//update user
const updateUserRequest = () => ({
  type: types.UPDATE_USER_REQUEST,
});

const updateUserSuccess = (user) => {
  return (dispatch, getState) => {
    const { users } = getState();
    const index = users.payload.findIndex((u) => u.id === user.id);
    users.payload[index] = user;
    dispatch(updateUsersList(users.payload));
  };
};

const updateUsersList = (users) => ({
  type: types.UPDATE_USER_LIST,
  payload: [...users],
});

const updateUserFailure = (errorRs) => ({
  type: types.UPDATE_USER_FAILURE,
  payload: {
    ...errorActionCreator(types.UPDATE_USER_FAILURE, errorRs),
  },
});

export const updateUser = (id, formData) => (dispatch) => {
  dispatch(updateUserRequest());
  return UserService.update(id, formData)
    .then(
      (res) => {
        dispatch(updateUserSuccess(res.data));
      },
      (error) => {
        dispatch(updateUserFailure({ error: error.response }));
      }
    )
    .finally(() => {
      return Promise.resolve("completed");
    });
};

//create user
const createUserRequest = () => ({
  type: types.CREATE_USER_REQUEST,
});

const createUserFailure = (error) => ({
  type: types.CREATE_USER_FAILURE,
  payload: {
    ...errorActionCreator(types.CREATE_USER_FAILURE, error),
  },
});

const createUserSuccess = (user) => {
  return (dispatch, getState) => {
    const { users } = getState();
    users.payload.push(user);
    dispatch(updateUsersList(users.payload));
  };
};

export const createUser = (formData) => (dispatch) => {
  dispatch(createUserRequest());
  return UserService.create(formData)
    .then(
      (res) => {
        dispatch(createUserSuccess(res.data));
      },
      (error) => {
        dispatch(createUserFailure({ error: error.response }));
      }
    )
    .finally(() => Promise.resolve("completed"));
};
// create user
