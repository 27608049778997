/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MySelect } from "../../form-fields/Select";

import { STORY_STATUS, STORY_STATUS_KEY } from "../../../constants/StoryStatus";
const UpdateAssesmentForm = ({
  assesment,
  handleClose,
  handleSubmit,
  isLoading,
}) => {
  const options = STORY_STATUS.map((i) => (
    <option value={i.value} label={i.label}></option>
  ));
  return (
    <div className="col-12 col-md-12">
      <Formik
        initialValues={{
          status: assesment.status ? assesment.status : STORY_STATUS_KEY.review,
        }}
        validationSchema={Yup.object({
          status: Yup.string().required(),
        })}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form style={{ width: "100%" }}>
            <div className="" style={{ width: "100%" }}>
              <MySelect
                name="status"
                label="Status"
                placeholder="Status"
                className="form-control "
              >
                {options}
              </MySelect>

              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-outline-primary px-5 mt-3"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-outline-primary px-5 mt-3"
                  disabled={formik.isSubmitting || !formik.isValid || isLoading}
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateAssesmentForm;
