/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DateInput } from "../../../form-fields/DateTime";
import { MySelect } from "../../../form-fields/Select";
import { MyTextArea } from "../../../form-fields/TextArea";
import { MyTextInput } from "../../../form-fields/TextInput";

const EditCollectionForm = ({
  collection,
  handleSubmit,
  handleClose,
  isCreating,
  topics,
}) => {
  const options = topics?.map((topic) => (
    <option key={topic.id} value={topic.id}>
      {topic.title}
    </option>
  ));
  return (
    <div>
      <Formik
        initialValues={{
          title: collection?.title,
          topic: collection?.topic?._path.segments[1],
          description: collection?.description,
          published_at: collection?.published_at
            ? new Date(
                collection.published_at._seconds * 1000 +
                  collection.published_at._nanoseconds / 1000000
              )
            : null,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required(),
          description: Yup.string().required(),
          published_at: Yup.date("Please enter a valid date").nullable(),
        })}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(formikProps) => (
          <Form>
            <MyTextInput
              name="title"
              label="Title"
              placeholder="Topic title"
              className="form-control"
            />
            <MySelect
              name="topic"
              label="Topic"
              plasceholder="Select topic"
              className="form-select"
              disabled
            >
              <option value="">Select a topic</option>
              {options}
            </MySelect>
            <MyTextArea
              name="description"
              label="Description"
              placeholder="Topic description"
              className="form-control"
            />

            <DateInput
              name="published_at"
              label="Published date"
              className="form-control"
            />
            <div className="d-flex justify-content-between mt-3">
              <button
                type="button"
                className="btn btn-outline-secondary px-5"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                disabled={
                  formikProps.isSubmitting || !formikProps.isValid || isCreating
                }
                className="btn btn-outline-primary px-5"
                type="submit"
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditCollectionForm;
