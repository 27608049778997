/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useField } from "formik";

export const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div>
      {label && (
        <label className="mt-2" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="alert alert-danger mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};
