/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import UserService from "../../services/UserService";
import { Error } from "../form-fields/Error";
import { MyTextInput } from "../form-fields/TextInput";

const Register = () => {
  const history = useHistory();
  const [error, setError] = useState("");

  const register = async (formData) => {
    try {
      await UserService.register({ ...formData });
      history.push("/login");
    } catch (e) {
      setError(e.error.data);
    }
  };

  return (
    <div className="col col-md-4 col-lg-3 vh-100 mx-5 mx-md-auto  d-flex flex-column justify-content-center">
      <h1>Sign up</h1>
      {error ? <Error message={error} /> : ""}
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
          passwordConfirmation: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string(),
          email: Yup.string()
            .email("Invalid email addresses")
            .required("Email is required"),
          password: Yup.string()
            .required("Password is required")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
              `Password should have at least 8 charachters and
                             contain at least one lowercase letter, 
                             one uppercase letter and a digit`
            ),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Password Cofirmation is required"),
        })}
        onSubmit={async (values) => {
          register(values);
        }}
      >
        {(formikProps) => (
          <Form>
            <MyTextInput
              className="form-control"
              name="name"
              type="text"
              placeholder="Full name"
              label="Name"
            />
            <MyTextInput
              className="form-control"
              name="email"
              type="email"
              placeholder="user@example.com"
              label="Login"
            />
            <MyTextInput
              className="form-control"
              name="password"
              type="password"
              placeholder="Password"
              label="Password"
            />
            <MyTextInput
              className="form-control"
              name="passwordConfirmation"
              type="password"
              label="Confirm password"
              placeholder="Confirm password"
            />
            <div className="d-flex justify-content-between mt-3">
              <button
                disabled={
                  formikProps.isSubmitting ||
                  !formikProps.isValid ||
                  !formikProps.dirty
                }
                className="btn btn-outline-primary flex-grow-1"
                type="submit"
              >
                Sign up
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <p className="mt-3">
        Have you already signed up? Please{" "}
        <Link to="/internal/login"> login here. </Link>
      </p>
    </div>
  );
};

export default Register;
