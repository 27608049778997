/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ROLES, USER_ROLES } from "../../../constants/Roles";
import { USER_STATUS } from "../../../constants/UserStatus";
import { MySwitch } from "../../form-fields/Checkbox";
import { MySelect } from "../../form-fields/Select";
import { MyTextArea } from "../../form-fields/TextArea";
import { MyTextInput } from "../../form-fields/TextInput";
const EditUserForm = ({ user, handleClose, updateUser, isLoading }) => {
  const options = USER_ROLES;
  const status_options = USER_STATUS.map((option) => (
    <option key={option.value} value={option.value}>
      {" "}
      {option.label}
    </option>
  ));
  return (
    <div>
      <Formik
        initialValues={{
          role: user.role,
          status: user.status,
          name: user.name,
          externalUrl: user.externalUrl,
          note: user.note,
          restricted: user.restricted,
        }}
        validationSchema={Yup.object({
          role: Yup.string(),
          externalUrl: Yup.string().url(),
        })}
        onSubmit={async (values) => {
          updateUser(values);
        }}
      >
        {(formikProps) => (
          <Form>
            <MyTextInput
              className="form-control"
              name="name"
              type="text"
              placeholder="Full name"
              label="Name"
            />
            <MySelect name="role" label="Role" className="form-control">
              {USER_ROLES.map((option) => (
                <option key={option.value} value={option.value}>
                  {" "}
                  {option.label}
                </option>
              ))}
            </MySelect>
            {formikProps.values.role === ROLES.PSYCHOLOGIST && (
              <MySwitch
                label={" "}
                name="restricted"
                placeholder="Restricted Access"
                className="form-control"
              />
            )}
            <MySelect
              name="status"
              label="Status"
              placeholder="User Status"
              className="form-control"
            >
              {status_options}
            </MySelect>
            <MyTextInput
              className="form-control"
              name="externalUrl"
              type="text"
              placeholder="External profile page"
              label="External profile page"
            />
            <MyTextArea
              className="form-control"
              name="note"
              type="text"
              placeholder="note"
              label="Note"
              noCount
            />

            <div className="d-flex justify-content-between mt-3">
              <button
                className="btn btn-outline-secondary px-5"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                disabled={
                  formikProps.isSubmitting || !formikProps.isValid || isLoading
                }
                className="btn btn-outline-primary px-5"
                type="submit"
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditUserForm;
