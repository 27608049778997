/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/misc`;

const MiscService = {
  getStoryStats: (formData) => {
    return axios.get(`${apiUrl}/storyStats`, formData);
  },
  getAllStats: (formData) => {
    return axios.get(`${apiUrl}/allStats`, formData);
  },
};

export default MiscService;
