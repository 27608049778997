/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { ROLES, userIs } from "../../../constants/Roles";
import StoryService from "../../../services/StoryService";
import TopicService from "../../../services/TopicService";
import { Error } from "../../form-fields/Error";
import UpdateTopicForm from "./UpdateTopicForm";

const ChangeCollection = (props) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const queryClient = useQueryClient();
  const [topics, setTopic] = useState([]);
  const didFieldChange = (oldValue, newValue) => {
    if (oldValue === newValue) {
      return false;
    }
    return true;
  };

  const fetchTopics = () => {
    TopicService.fetchAll().then((res) => {
      setTopic(res.data);
    });
  };

  const handleSubmit = async (formData) => {
    const data = { ...formData };
    console.log(props.story.topic._path.segments[1], data.topic);
    setIsLoading(true);
    if (
      !didFieldChange(props.story.topic._path.segments[1], data.topic) &&
      userIs(ROLES.ADMIN)
    ) {
      props.handleClose();
      setIsLoading(false);
      return;
    }
    StoryService.updateTopic(props.story.id, data.topic).then(() => {
      setIsLoading(false);
      props.handleClose();
      queryClient.invalidateQueries("stories");
    });
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      dialogClassName="modal-90w"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Topic</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} />
        <UpdateTopicForm
          handleSubmit={handleSubmit}
          handleClose={props.handleClose}
          collections={props.collections}
          story={props.story}
          topics={topics}
          didFieldChange={didFieldChange}
          isLoading={isLoading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ChangeCollection;
