/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/users`;

const UserService = {
  login: (formData) => {
    return axios.post(`${apiUrl}/login`, formData);
  },
  register: (formData) => {
    delete formData.passwordConfirmation;
    return axios.post(`${apiUrl}/register`, formData);
  },
  reset: (formData) => {
    return axios.post(`${apiUrl}/reset-password`, formData);
  },
  recover: (formData) => {
    return axios.put(`${apiUrl}/recover-password`, formData);
  },
  createPassword: (formData) => {
    return axios.put(`${apiUrl}/create-password`, formData);
  },
  updateProfile: (formData) => {
    return axios.put(`${apiUrl}`, formData);
  },
  fetchAll: () => {
    return axios.get(`${apiUrl}`);
  },
  fetchUser: (id) => {
    return axios.get(`${apiUrl}/${id}`);
  },
  update: (id, formData) => {
    return axios.put(`${apiUrl}/${id}`, formData);
  },
  create: (formData) => {
    return axios.post(`${apiUrl}`, formData);
  },
  fetchAuthors: (role = null) => {
    return axios.get(`${apiUrl}/authors?${role ? "role=" + role : ""}`);
  },
  fetchPsychologists: (role = null) => {
    return axios.get(`${apiUrl}/psychologists?${role ? "role=" + role : ""}`);
  },
  randomPass: (id) => {
    return axios.post(`${apiUrl}/getRandoPass/${id}`);
  },
};

export default UserService;
