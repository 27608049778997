/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ROLES, userIs } from "../../constants/Roles";
import CollectionService from "../../services/CollectionService";
import CommentService from "../../services/CommentService";
import Helpers from "../../utils/helpers";
import { Error } from "../form-fields/Error";
import { STORY_STATUS_KEY } from "./../../constants/StoryStatus";
import ViewComment from "./view/View";

import Loader from "../../components/utils/Loader";
const CommentsList = ({
  comments,
  story,
  handleSkip,
  withSkip,
  type,
  assesmentId,
  stats,
  requeueStatus,
}) => {
  const [comment, setComment] = useState("");
  const [error, setError] = useState(null);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("");

  useEffect(() => {
    userIs(ROLES.ADMIN) && fetchCollections();
  }, []);
  const fetchCollections = () => {
    CollectionService.fetchAll().then((res) => {
      setCollections(res.data);
      let defaultCollection = res.data.find(
        (i) => i.id == localStorage.getItem("selectedCollection")
      );
      if (defaultCollection) {
        setSelectedCollection(defaultCollection.id);
      }
    });
  };
  const commentsList =
    comments &&
    comments.map((comment) => (
      <ViewComment key={comment.id} comment={comment} />
    ));
  const mutation = useMutation((formData) => CommentService.create(formData), {
    onSuccess: (data) => {
      setComment("");
      window.location.reload();
    },
    onError: (error) => {
      setError(Helpers.errorCreator(error));
    },
  });

  const handleSubmit = (status = null) => {
    const data = {
      body: comment,
      storyId: story.id,
      collection: selectedCollection,
      status: status ? status : undefined,
      assesment: type && type === "assesment" ? assesmentId : undefined,
    };

    mutation.mutate(data);
  };
  const collectionOptions = collections.map((i) => (
    <option value={i.id} label={i.title}></option>
  ));
  const handleChangeSelectedCollection = (value) => {
    localStorage.setItem("selectedCollection", value);
    setSelectedCollection(value);
  };
  return (
    <>
      <h5 style={{ marginLeft: "15px" }}>Comments</h5>
      <Error error={error} />
      <Loader show={mutation.isLoading} />
      <div className="my-3 list-group" style={{ borderTop: "none" }}>
        <div style={{ padding: "10px" }}>
          <textarea
            className="comment-input"
            type="text"
            placeholder="leave a comment here "
            // rows="5"
            value={comment}
            style={{
              border: "none",
              borderBottom: "2px solid #ccc",
              backgroundColor: "inherit",
              padding: "5px 8px",
              fontSize: "16px",
              width: "100%",
            }}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
          <div className="container pt-2 d-flex flex-wrap">
            <button
              disabled={comment.trim().length <= 1}
              className="btn btn-primary ms-2 mb-2"
              onClick={() => handleSubmit()}
            >
              Comment
            </button>
            {(story.status === STORY_STATUS_KEY.review ||
              story.status === undefined ||
              type === "assesment") &&
              userIs(ROLES.ADMIN) && (
                <>
                  <button
                    disabled={comment.trim().length <= 1}
                    className="btn btn-success ms-2 mb-2"
                    onClick={() => handleSubmit(STORY_STATUS_KEY.finished)}
                  >
                    Approve and Comment
                  </button>
                  {type !== "assesment" && (
                    <div className="mb-2 ms-2 d-flex align-center">
                      <button
                        disabled={
                          comment.trim().length <= 1 || !selectedCollection
                        }
                        className="btn btn-success "
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          marginTop: 0,
                        }}
                        onClick={() => handleSubmit(STORY_STATUS_KEY.finished)}
                      >
                        Approve and add to
                      </button>

                      <select
                        name="assigned_writers"
                        label="Assigned writers"
                        className="h-100 px-2"
                        defaultValue={"default"}
                        value={selectedCollection}
                        onChange={(e) => {
                          handleChangeSelectedCollection(e.target.value);
                        }}
                      >
                        <option disabled defaultChecked key="default" value="">
                          Select a collection
                        </option>
                        {collectionOptions}
                      </select>
                    </div>
                  )}
                  <button
                    disabled={comment.trim().length <= 1}
                    className="btn btn-danger ms-2 mb-2"
                    onClick={() => handleSubmit(STORY_STATUS_KEY.rework)}
                  >
                    Reject and Comment
                  </button>

                  {withSkip && (
                    <>
                      <button
                        className="btn btn-danger ms-2 mb-2"
                        onClick={() => handleSkip()}
                      >
                        Skip
                      </button>
                      <button
                        className="btn btn-primary ms-2 mb-2"
                        onClick={() => requeueStatus()}
                      >
                        Requeue
                      </button>
                      <h6 className="ms-4 pt-2">
                        {stats?.Review ? stats.Review + " Reviews left" : ""}
                      </h6>
                    </>
                  )}
                </>
              )}
          </div>
        </div>
        <div className="">{commentsList}</div>
      </div>
    </>
  );
};

export default CommentsList;
