/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import Loader from "../components/utils/Loader";
import { STORY_STATUS_KEY } from "../constants/StoryStatus";
import UserService from "../services/UserService";
import Helpers from "../utils/helpers";
export default function Writers() {
  const [error, setError] = useState(null);
  const fetchPsychologists = () => {
    return UserService.fetchPsychologists();
  };
  const {
    isLoading,
    isFetching,
    data: authors,
  } = useQuery(["Psychologists"], fetchPsychologists, {
    onSuccess: (response) => {},
    onError: (error) => {
      setError(Helpers.errorCreator(error));
    },
  });

  const totalStatsInArray = authors?.data
    ?.map((writer) => [
      writer?.assessmentStats?.[STORY_STATUS_KEY.review] || 0,
      writer?.assessmentStats?.[STORY_STATUS_KEY.rework] || 0,
      writer?.assessmentStats?.[STORY_STATUS_KEY.finished] || 0,
      writer?.assessmentStats?.[STORY_STATUS_KEY.abandoned] || 0,
      writer?.assessmentStats?.[STORY_STATUS_KEY.selected] || 0,
    ])
    ?.reduce(
      (a, b) => [
        a[0] + b[0],
        a[1] + b[1],
        a[2] + b[2],
        a[3] + b[3],
        a[4] + b[4],
      ],
      [0, 0, 0, 0, 0]
    );

  const totalStats = {
    [STORY_STATUS_KEY.review]: totalStatsInArray?.[0] || 0,
    [STORY_STATUS_KEY.rework]: totalStatsInArray?.[1] || 0,
    [STORY_STATUS_KEY.finished]: totalStatsInArray?.[2] || 0,
    [STORY_STATUS_KEY.abandoned]: totalStatsInArray?.[3] || 0,
    [STORY_STATUS_KEY.selected]: totalStatsInArray?.[4] || 0,
  };

  const tableData = React.useMemo(
    () =>
      authors?.data
        ? authors?.data?.map((writer) => {
            return {
              name: writer.name,
              [STORY_STATUS_KEY.review]:
                writer?.assessmentStats?.[STORY_STATUS_KEY.review] || 0,
              [STORY_STATUS_KEY.rework]:
                writer?.assessmentStats?.[STORY_STATUS_KEY.rework] || 0,
              [STORY_STATUS_KEY.finished]:
                writer?.assessmentStats?.[STORY_STATUS_KEY.finished] || 0,
              [STORY_STATUS_KEY.abandoned]:
                writer?.assessmentStats?.[STORY_STATUS_KEY.abandoned] || 0,
              [STORY_STATUS_KEY.selected]:
                writer?.assessmentStats?.[STORY_STATUS_KEY.selected] || 0,
              id: writer.id,
              status: writer.status,
              restricted: writer.restricted,
            };
          })
        : [],

    [authors]
  );

  const tableDataColumn = React.useMemo(
    () => [
      {
        Header: "User",
        accessor: "name",
        Cell: (data) => {
          return (
            <Link
              to={`/internal/profile/${data.cell.row.original.id}`}
              className="user-link ms-2"
            >
              {data.cell.row.original.status === "DEACTIVATED" ? (
                <strike>{data.value}</strike>
              ) : (
                data.value + (data.cell.row.original.restricted ? " ✋" : "")
              )}
            </Link>
          );
        },
      },
      {
        Header: `Review (${totalStats[STORY_STATUS_KEY.review]})`,
        accessor: STORY_STATUS_KEY.review,
        Cell: (data) => {
          return (
            <Link
              to={`/internal/assessments?author=${data.cell.row.original.id}&status=${STORY_STATUS_KEY.review}`}
              className="label label-default"
            >
              {data.value || 0}
            </Link>
          );
        },
        sortType: "basic",
      },
      {
        Header: `Rework (${totalStats[STORY_STATUS_KEY.rework]})`,
        accessor: STORY_STATUS_KEY.rework,
        Cell: (data) => {
          return (
            <Link
              to={`/internal/assessments?author=${data.cell.row.original.id}&status=${STORY_STATUS_KEY.rework}`}
              className="label label-default"
            >
              {data.value || 0}
            </Link>
          );
        },
        sortType: "basic",
      },
      {
        Header: `Finished (${totalStats[STORY_STATUS_KEY.finished]})`,
        accessor: STORY_STATUS_KEY.finished,
        Cell: (data) => {
          return (
            <Link
              to={`/internal/assessments?author=${data.cell.row.original.id}&status=${STORY_STATUS_KEY.finished}`}
              className="label label-default"
            >
              {data.value || 0}
            </Link>
          );
        },
        sortType: "basic",
      },
      {
        Header: `Abandoned (${totalStats[STORY_STATUS_KEY.abandoned]})`,
        accessor: STORY_STATUS_KEY.abandoned,
        Cell: (data) => {
          return (
            <Link
              to={`/internal/assessments?author=${data.cell.row.original.id}&status=${STORY_STATUS_KEY.abandoned}`}
              className="label label-default"
            >
              {data.value || 0}
            </Link>
          );
        },
        sortType: "basic",
      },
      {
        Header: `Selected (${totalStats[STORY_STATUS_KEY.selected]})`,
        accessor: STORY_STATUS_KEY.selected,
        Cell: (data) => {
          return (
            <Link
              to={`/internal/assessments?isSelected=true`}
              className="label label-default"
            >
              {data.value || 0}
            </Link>
          );
        },
      },
    ],
    [authors]
  );

  const tableInstance = useTable(
    { columns: tableDataColumn, data: tableData },
    useSortBy
  );
  const { getTableProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <Loader show={isLoading} />
      <div className="container bootstrap snippets bootdey mt-4 ">
        <div className="row  d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="main-box no-header clearfix">
              <div className="main-box-body clearfix">
                <div className="table-responsive">
                  {authors?.data && (
                    <table className="table user-list" {...getTableProps()}>
                      <thead>
                        {
                          // Loop over the header rows
                          headerGroups.map((headerGroup) => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {
                                // Loop over the headers in each row
                                headerGroup.headers.map((column) => (
                                  // Apply the header cell props
                                  <th
                                    className="text-center"
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps()
                                    )}
                                  >
                                    <span> {column.render("Header")} </span>
                                    <span>
                                      {column.isSorted
                                        ? column.isSortedDesc
                                          ? " ▼"
                                          : " ▲"
                                        : ""}
                                    </span>
                                  </th>
                                ))
                              }
                            </tr>
                          ))
                        }
                      </thead>
                      <tbody>
                        {" "}
                        {
                          // Loop over the table rows
                          rows.map((row) => {
                            // Prepare the row for display
                            prepareRow(row);
                            return (
                              // Apply the row props
                              <tr {...row.getRowProps()}>
                                {
                                  // Loop over the rows cells
                                  row.cells.map((cell) => {
                                    // Apply the cell props
                                    return (
                                      <td
                                        {...cell.getCellProps()}
                                        align="center"
                                      >
                                        {
                                          // Render the cell contents

                                          cell.render("Cell")
                                        }
                                      </td>
                                    );
                                  })
                                }
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
