/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useField } from "formik";
import Helper from "../../utils/helpers";
export const MyTextArea = ({ label, noCount, ...props }) => {
  const [field, meta] = useField(props);

  const onChange = (e) => {
    field.onChange(e);
  };
  let words = field?.value ? Helper.getWordCount(field.value) : 0;

  return (
    <>
      <label className="mt-2" htmlFor={props.id || props.name}>
        {label}
      </label>
      <textarea
        className="text-input"
        {...field}
        {...props}
        onChange={onChange}
      />
      {!noCount && (
        <div className="mt-1" style={{ color: Helper.getCountColor(words) }}>
          {words} words
        </div>
      )}
      {meta.error ? (
        <div className="alert alert-danger small py-1 my-1">{meta.error}</div>
      ) : null}
    </>
  );
};
