/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../../form-fields/TextInput";

const UpdateLogin = ({ user, updateProfile }) => {
  return (
    <Formik
      initialValues={{
        email: user.email,
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Invalid email addresses")
          .required("Email is required"),
      })}
      onSubmit={async (values) => {
        updateProfile(values);
      }}
    >
      {(formikProps) => (
        <Form>
          <div className="d-flex gap-3">
            <div className="flex-grow-1">
              <MyTextInput
                className="form-control flex-grow-0"
                name="email"
                type="email"
                placeholder="user@example.com"
              />
            </div>
            <div className="mt-4">
              <button
                disabled={formikProps.isSubmitting || !formikProps.isValid}
                className="btn btn-primary"
                type="submit"
              >
                Change email
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateLogin;
