/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import StoryService from "../../../services/StoryService";
import StoryCard from "../published/StoryCard";

const StoriesList = () => {
  const { isLoading, isFetching, data, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ["allPublished"],
      ({ pageParam }) =>
        StoryService.fetchAllPublished({
          startAfter: pageParam,
        }),
      {
        keepPreviousData: true,
        getNextPageParam: (lastPage) => {
          if (!lastPage.data.stories.length) {
            return undefined;
          } else
            return lastPage.data.stories[lastPage.data.stories.length - 1].id;
        },
        getPreviousPageParam: (lastPage) => {
          if (!lastPage?.data?.length) return null;
          else
            return lastPage.data.stories[lastPage.data.stories.length - 1].id;
        },
      }
    );

  const allAssessments = data?.pages?.flatMap((i) => i.data.stories) ?? [];

  return (
    <div className="my-3 list-group">
      <InfiniteScroll
        dataLength={allAssessments.length} //This is important field to render the next data
        next={() => fetchNextPage()}
        hasMore={hasNextPage}
        loading={isLoading}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            {isLoading || isFetching ? (
              <b>Loading...</b>
            ) : (
              <b>No more stories available</b>
            )}
          </p>
        }
      >
        {allAssessments?.map((story) => (
          <StoryCard story={story} id={story.id}></StoryCard>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default StoriesList;
