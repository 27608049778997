/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import axios from "axios";
import { useEffect, useState } from "react";
import CollectionService from "../../services/CollectionService";

const SyncButton = () => {
  const [lastSyncDate, setLastSyncDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const onClickSync = () => {
    setLoading(true);
    CollectionService.syncCollections()
      .then(() => getLastSyncDate())
      .finally(() => setLoading(false));
  };

  const getLastSyncDate = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_CDN
        }/app-data/0.json?timestamp=${new Date().toISOString()}`
      )
      .then((response) => {
        if (response.data?.updated) {
          setLastSyncDate(new Date(response.data.updated));
        }
      });
  };
  useEffect(() => {
    getLastSyncDate();
  }, []);
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex mx-3">
        Last synced on {lastSyncDate && lastSyncDate.toLocaleString()}
      </div>
      <button className="btn btn-primary" onClick={onClickSync}>
        {loading ? <div className="spinner-border" /> : "Sync"}
      </button>
    </div>
  );
};

export default SyncButton;
