/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import Helpers from "../utils/helpers";

export const USER_ROLES = [
  { value: "admin", label: "Admin" },
  { value: "writer", label: "Writer" },
  { value: "psychologist", label: "Psychologist" },
];

export const ROLES = {
  ADMIN: "admin",
  WRITER: "writer",
  PSYCHOLOGIST: "psychologist",
};

export const userIs = (...roles) => {
  return roles.includes(Helpers.getUser()?.role);
};
