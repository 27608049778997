/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ROLES, userIs } from "../../../constants/Roles";
import StoryService from "../../../services/StoryService";
import Helpers from "../../../utils/helpers";
import { Error } from "../../form-fields/Error";
import Loader from "../../utils/Loader";
import UserService from "./../../../services/UserService";
import CreateStoryForm from "./CreateForm";

const CreateStory = () => {
  const [error, setError] = useState(null);
  const [authors, setAuthors] = useState([]);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const history = useHistory();

  const mutation = useMutation((story) => StoryService.create(story), {
    onSuccess: () => {
      queryClient.invalidateQueries(["stories", { topicId: id }]);
    },
    onError: (error) => {
      setError(Helpers.errorCreator(error));
    },
    onSettled: () => {
      handleCancel();
    },
  });

  const fetchAuthors = () => {
    if (userIs(ROLES.ADMIN)) {
      return UserService.fetchAuthors();
    } else {
      return new Promise((resolve) => resolve([]));
    }
  };

  const { isLoading, isFetching } = useQuery("authors", fetchAuthors, {
    onSuccess: (response) => {
      setAuthors(response.data);
    },
    onError: (error) => {
      setError(Helpers.errorCreator(error));
    },
  });

  const handleSubmit = (story) => {
    const data = { topicId: id, ...story };
    mutation.mutate(data);
  };

  const handleCancel = () => {
    history.push(`/internal/topics/${id}`);
  };

  return (
    <div className="container my-5">
      <Loader show={isLoading || isFetching} />
      <Error error={error} />
      <CreateStoryForm
        authors={authors}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        isLoading={mutation.isLoading}
      />
    </div>
  );
};

export default CreateStory;
