/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Error } from "../form-fields/Error";
import { MyTextInput } from "../form-fields/TextInput";
import Loader from "../utils/Loader";
import { login } from "./../../states/actions/userAction";

const Login = ({ login, user }) => {
  const isLoading = user && user.isLoading;
  const error = user && user.error;

  return (
    <>
      <Loader show={isLoading} />
      <div className="col col-md-4 col-lg-3 vh-100 mx-5 mx-md-auto  d-flex flex-column justify-content-center">
        <h1>Sign in</h1>
        <Error error={error} />
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string().required("Email is required"),
            password: Yup.string().required("Passwrod is required"),
          })}
          onSubmit={async (values) => {
            login(values);
          }}
        >
          {(formikProps) => (
            <Form>
              <MyTextInput
                className="form-control"
                name="email"
                type="email"
                placeholder="user@example.com"
                label="Login"
              />
              <MyTextInput
                className="form-control"
                name="password"
                type="password"
                placeholder="Password"
                label="Password"
              />
              <div className="d-flex justify-content-between mt-3">
                <button
                  disabled={
                    formikProps.isSubmitting ||
                    !formikProps.isValid ||
                    !formikProps.dirty
                  }
                  className="btn btn-primary flex-grow-1"
                  type="submit"
                >
                  Sign in
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="d-sm-flex gap-1">
          {/*
          <Link
            to="/internal/register"
            className="btn btn-sm btn-outline-secondary mt-3 w-100 w-sm-50"
            disabled
          >
            Create an account
          </Link>*/}
          <Link
            to="/internal/forgot-password"
            className="btn btn-sm btn-outline-secondary mt-3 w-100 w-sm-50"
          >
            Forgot password
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { login })(Login);
