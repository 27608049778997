/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ROLES, userIs } from "../../../constants/Roles";
import AssesmentService from "../../../services/AssesmentService";
import Helpers from "../../../utils/helpers";
import { Error } from "../../form-fields/Error";
import Loader from "../../utils/Loader";
import UserService from "./../../../services/UserService";
import UpdateStoryForm from "./UpdateAssesmentForm";
const SubmitAssesment = (props) => {
  const [error, setError] = useState(null);
  const [authors, setAuthors] = useState([]);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (formData) =>
      AssesmentService.create({ ...formData, storyId: props.story.id }),
    {
      onSuccess: (data) => {
        props.handleClose();
        queryClient.invalidateQueries("assesments");
      },
      onError: (error) => {
        setError(Helpers.errorCreator(error));
      },
    }
  );

  const fetchPsychologists = () => {
    if (userIs(ROLES.ADMIN)) {
      return UserService.fetchPsychologists();
    } else {
      return new Promise((resolve) => resolve([]));
    }
  };

  const { isLoading, isFetching } = useQuery(
    "psychologists",
    fetchPsychologists,
    {
      onSuccess: (response) => {
        setAuthors(response.data);
      },
      onError: (error) => {
        setError(Helpers.errorCreator(error));
      },
    }
  );

  const handleSubmit = async (formData) => {
    const data = { ...formData };

    mutation.mutate(data);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      dialogClassName="modal-90w"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Submit Assessment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader show={isLoading || isFetching} />
        <Error error={error} />
        <UpdateStoryForm
          handleSubmit={handleSubmit}
          handleClose={props.handleClose}
          isCreating={mutation.isLoading}
          authors={authors}
          story={props.story}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SubmitAssesment;
