/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import axios from "axios";

const CollectionService = {
  fetchAll: () => axios.get(`${process.env.REACT_APP_API_URL}/collections`),
  create: (formData) =>
    axios.post(`${process.env.REACT_APP_API_URL}/collections`, formData),
  update: ({ id, data }) =>
    axios.put(`${process.env.REACT_APP_API_URL}/collections/${id}`, data),
  fetchOne: (id) =>
    axios.get(`${process.env.REACT_APP_API_URL}/collections/${id}`),
  allAssessed: () =>
    axios.get(`${process.env.REACT_APP_API_URL}/collections/assessed`),
  getPublishing: () =>
    axios.get(`${process.env.REACT_APP_API_URL}/collections/publishing`),
  exportCollection: (id, params) =>
    axios.get(`${process.env.REACT_APP_API_URL}/collections/${id}/export`, {
      params: params,
    }),
  syncCollections: () =>
    axios.get(`${process.env.REACT_APP_API_URL}/misc/sync`),
  importCollectionAssessments: (id, data) =>
    axios.post(
      `${process.env.REACT_APP_API_URL}/collections/${id}/import/assessments`,
      data
    ),
};

export default CollectionService;
