/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../../form-fields/TextInput";

const UpdatePassword = ({ updateProfile }) => {
  return (
    <Formik
      initialValues={{
        password: "",
      }}
      validationSchema={Yup.object({
        password: Yup.string().matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
          `Password should have at least 8 charachters and
                         contain at least one lowercase letter, 
                         one uppercase letter and a digit`
        ),
      })}
      onSubmit={async (values, { resetForm }) => {
        updateProfile(values);
        resetForm({});
      }}
    >
      {(formikProps) => (
        <Form>
          <div className="d-flex gap-3">
            <div className="flex-grow-1">
              <MyTextInput
                className="form-control flex-grow-0"
                name="password"
                type="password"
                placeholder="Enter your new password"
              />
            </div>
            <div className="mt-4">
              <button
                disabled={
                  formikProps.isSubmitting ||
                  !formikProps.isValid ||
                  !formikProps.dirty
                }
                className="btn btn-primary"
                type="submit"
              >
                Change password
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePassword;
