/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import TopicService from "../../../../services/TopicService";
import Helpers from "../../../../utils/helpers";
import { Error } from "../../../form-fields/Error";
import CreateTopicForm from "./CreateForm";

const CreateTopic = ({ show, handleClose }) => {
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  const mutation = useMutation((formData) => TopicService.create(formData), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("topics");
      handleClose();
    },
    onError: (error) => {
      setError(Helpers.errorCreator(error));
    },
  });

  const handleSubmit = async (formData) => {
    const data = { ...formData };
    mutation.mutate(data);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Topic</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} />
        <CreateTopicForm
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          isCreating={mutation.isLoading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CreateTopic;
