/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { ROLES, userIs } from "../../../constants/Roles";
import StoryService from "../../../services/StoryService";
import Helpers from "../../../utils/helpers";
import { Error } from "../../form-fields/Error";
import UpdateStatusForm from "./UpdateStatusForm";

const StoryStatusModal = (props) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const queryClient = useQueryClient();
  const didFieldChange = (oldValue, newValue) => {
    if (oldValue === newValue) {
      return false;
    }
    return true;
  };
  const mutation = useMutation(
    (formData) => StoryService.submitNewStoryVersion(formData, props.story.id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("topics");
        props.handleClose();
        window.location.reload();
      },
      onError: (error) => {
        setError(Helpers.errorCreator(error));
      },
    }
  );

  const handleSubmit = async (formData) => {
    const data = { ...formData };
    setIsLoading(true);
    if (
      !didFieldChange(props.story.status, data.status) &&
      userIs(ROLES.ADMIN)
    ) {
      props.handleClose();
      setIsLoading(false);
      return;
    }
    props.updateStatus(data).then(() => {
      setIsLoading(false);
    });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      dialogClassName="modal-90w"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} />
        <UpdateStatusForm
          handleSubmit={handleSubmit}
          handleClose={props.handleClose}
          isCreating={mutation.isLoading}
          story={props.story}
          didFieldChange={didFieldChange}
          isLoading={isLoading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default StoryStatusModal;
