/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { ROLES, USER_ROLES } from "../../../constants/Roles";
import { MySwitch } from "../../form-fields/Checkbox";
import { MySelect } from "../../form-fields/Select";
import { MyTextInput } from "../../form-fields/TextInput";
const CreateUserForm = ({ handleClose, createUser, usersState, refetch }) => {
  const [submitting, handleSubmitting] = React.useState(false);
  const options = USER_ROLES;

  const toggleSubmitting = () => {
    handleSubmitting(!submitting);
  };
  const createNewUser = async (formData) => {
    toggleSubmitting();

    await createUser(formData);
    refetch();
    handleClose();
    toggleSubmitting();
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          role: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string(),
          email: Yup.string()
            .email("Invalid email addresses")
            .required("Email is required"),
          role: Yup.string(),
        })}
        onSubmit={async (values) => {
          createNewUser({ ...values });
        }}
      >
        {(formikProps) => (
          <Form>
            <MyTextInput
              className="form-control"
              name="name"
              type="text"
              placeholder="Full name"
              label="Name"
            />
            <MyTextInput
              className="form-control"
              name="email"
              type="email"
              placeholder="user@example.com"
              label="Email"
            />
            <MySelect name="role" label="Role" className="form-control">
              {USER_ROLES.map((option) => (
                <option key={option.value} value={option.value}>
                  {" "}
                  {option.label}
                </option>
              ))}
            </MySelect>
            {formikProps.values.role === ROLES.PSYCHOLOGIST && (
              <MySwitch
                label={" "}
                name="restricted"
                placeholder="Restricted Access"
                className="form-control"
              />
            )}
            <div className="d-flex justify-content-between mt-3">
              <button
                type="button"
                className="btn btn-outline-secondary px-3"
                onClick={handleClose}
                disabled={submitting}
              >
                Cancel
              </button>
              <button
                disabled={
                  submitting ||
                  formikProps.isSubmitting ||
                  !formikProps.isValid ||
                  !formikProps.dirty
                }
                className="btn btn-outline-primary px-3"
                type="submit"
              >
                Create
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateUserForm;
