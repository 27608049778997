/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useField } from "formik";
import moment from "moment";

export const DateInput = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  return (
    <>
      <label
        className="mt-2 d-flex justify-content-between"
        htmlFor={props.id || props.name}
      >
        {label}{" "}
        <span onClick={() => setValue(null)} className="text-danger">
          Delete
        </span>
      </label>
      <input
        type="datetime-local"
        {...field}
        {...props}
        onChange={(e) => setValue(new Date(e.target.value))}
        value={moment(field.value).format("yyyy-MM-DDThh:mm")}
      />

      {meta.touched && meta.error ? (
        <div className="alert alert-danger small py-1 my-1">{meta.error}</div>
      ) : null}
    </>
  );
};
