/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import moment from "moment";
import { useRef, useState } from "react";
import Linkify from "react-linkify";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";
import { ROLES, userIs } from "../../../../constants/Roles";
import Helpers from "../../../../utils/helpers";
import { Error } from "../../../form-fields/Error";
import StoryList from "../../../stories/FilterView";
import Loader from "../../../utils/Loader";
import CollectionService from "./../../../../services/CollectionService";

const ViewCollection = () => {
  const [collection, setCollection] = useState(null);
  const [error, setError] = useState(null);
  const [importingAssessments, setImportingAssessments] = useState(false);
  const importedAssessments = useRef(null);
  const params = useParams();
  const queryClient = useQueryClient();

  const exportCollection = (assessments) =>
    CollectionService.exportCollection(params.id, { assessments: assessments })
      .then((response) => response.data)
      .then((text) => navigator.clipboard.writeText(text))
      .catch((error) => setError(Helpers.errorCreator(error)));
  const importCollectionAssessments = (text) =>
    CollectionService.importCollectionAssessments(params.id, text)
      .then(() => setImportingAssessments(false))
      .then(() => queryClient.invalidateQueries("collection"))
      .catch((error) => setError(Helpers.errorCreator(error)));
  const { isLoading, isFetching } = useQuery(
    ["collection", { id: params.id }],
    ({ queryKey }) => {
      const { id } = queryKey[1];
      return CollectionService.fetchOne(id);
    },
    {
      onSuccess: (response) => setCollection(response.data),
      onError: (error) => setError(Helpers.errorCreator(error)),
    }
  );

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
  const publishedDate =
    collection?.published_at &&
    new Date(collection?.published_at._seconds * 1000);
  return (
    <>
      <Loader show={isLoading || isFetching} />
      <div className="container my-5">
        <Error error={error} />
        {collection && (
          <>
            <div className="card bg-light">
              <div className="card-header d-flex justify-content-start">
                {userIs(ROLES.ADMIN) && (
                  <>
                    <span className="ms-2 text-muted">
                      {collection?.count || 0} Stories
                    </span>
                    <span className="ms-2 text-muted">
                      {collection?.assessed || 0} assessed
                    </span>
                  </>
                )}
              </div>
              <div className="card-body">
                <h5 className="card-title flex-grow-1">{collection.title}</h5>
                <p>
                  <Linkify componentDecorator={componentDecorator}>
                    {Helpers.convertMultiLine(collection.description)}
                  </Linkify>
                </p>
                {publishedDate < new Date() && publishedDate ? (
                  <small className="text-success">
                    Published on :{" "}
                    {moment(publishedDate).format("DD MMM yyyy hh:mm")}
                  </small>
                ) : publishedDate > new Date() && publishedDate ? (
                  <small className="text-success">
                    Will publish on :{" "}
                    {moment(publishedDate).format("DD MMM yyyy hh:mm")}
                  </small>
                ) : (
                  <small className="text-danger">Not Published</small>
                )}
              </div>
              <div className="card-footer d-flex justify-content-end text-secondary text-link px-2">
                {` Created ${Helpers.convertTime(collection.created_at)}`}
                {` Last updated ${Helpers.convertTime(collection.updated_at)}`}
              </div>
            </div>
            <button
              className="btn btn-primary mt-4"
              onClick={() => exportCollection("none")}
            >
              Export without assessments
            </button>
            <button
              className="btn btn-primary mt-4 mx-4"
              onClick={() => exportCollection("selected")}
            >
              Export with selected assessments
            </button>
            <button
              className="btn btn-primary mt-4"
              onClick={() => exportCollection("all")}
            >
              Export all assessments
            </button>
            <button
              className="btn btn-primary mt-4 mx-4"
              onClick={() =>
                importingAssessments
                  ? importCollectionAssessments({
                      text: importedAssessments.current.value,
                    })
                  : setImportingAssessments(!importingAssessments)
              }
            >
              {importingAssessments || "Import assessments"}
              {importingAssessments && "Upload assessments"}
            </button>
            <br />
            <textarea
              className="mt-4 form-control"
              hidden={!importingAssessments}
              ref={importedAssessments}
            />
          </>
        )}
        <StoryList collection={params.id} />
      </div>
    </>
  );
};

export default ViewCollection;
