/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
const {
  LIST_USERS,
  LIST_USERS_REQUEST,
  LIST_USERS_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_LIST,
  UPDATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_FAILURE,
  CREATE_USER_SUCCESS,
} = require("../../constants/ActionTypes");

const users = (state = [], action) => {
  switch (action.type) {
    case LIST_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LIST_USERS:
      return {
        ...state,
        error: null,
        isLoading: false,
        payload: [...action.payload],
      };
    case LIST_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUserError: null,
        isUserUpdating: true,
      };
    case UPDATE_USER_LIST:
      return {
        ...state,
        payload: [...action.payload],
        updateUserError: null,
        isUserUpdating: false,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserError: action.payload,
        isUserUpdating: false,
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        createUserError: null,
        isCreatingUser: true,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        payload: [...action.payload],
        updateUserError: null,
        isUserUpdating: false,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        createUserError: action.payload,
        isCreatingUser: false,
      };
    default:
      return state;
  }
};

export default users;
