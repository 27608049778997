/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import * as types from "../../constants/ActionTypes";
import UserService from "../../services/UserService";
import { errorActionCreator } from "./errorAction";

//login user
const loginUserRequest = () => ({
  type: types.LOGIN_USER_REQUEST,
});

const loginUserSuccess = (user) => ({
  type: types.LOGIN_USER_SUCCESS,
  payload: {
    ...user,
  },
});

const loginUserFailure = (errorRs) => ({
  type: types.LOGIN_USER_FAILURE,
  payload: {
    ...errorActionCreator(types.LOGIN_USER_FAILURE, errorRs),
  },
});

export const login = (formData) => (dispatch) => {
  dispatch(loginUserRequest());
  UserService.login(formData).then(
    (res) => {
      saveUser(res.data);
      window.location.reload();
      dispatch(loginUserSuccess(res.data));
    },
    (error) => {
      console.error(error);
      dispatch(loginUserFailure({ error: error.response }));
    }
  );
};

//login user

//logout user
const logoutUser = () => ({
  type: types.LOGOUT_USER,
});

export const logout = () => (dispatch) => {
  localStorage.removeItem("lyflair_user");
  dispatch(logoutUser());
};
//logout user

const saveUser = (user) => {
  localStorage.setItem("lyflair_user", JSON.stringify(user));
};

export const getCurrentUser = () => (dispatch) => {
  let user = localStorage.getItem("lyflair_user");
  if (user) {
    user = JSON.parse(user);
    dispatch(loginUserSuccess(user));
  }
};

export const resetPassword = (formData) => {
  return UserService.reset(formData);
};

export const recoverPassword = (formData) => {
  delete formData.passwordConfirmation;
  return UserService.recover(formData);
};

export const createPassword = (formData) => {
  return UserService.createPassword(formData);
};

const updateProfileRequest = () => {
  return (dispatch, getState) => {
    const { user } = getState();
    dispatch({
      type: types.UPDATE_PROFILE_REQUEST,
      payload: user ? user.payload : null,
    });
  };
};

const updateProfileFailure = (errorRs) => ({
  type: types.UPDATE_PROFILE_FAILURE,
  payload: {
    ...errorActionCreator(types.UPDATE_PROFILE_FAILURE, errorRs),
  },
});

export const updateProfile = (formData) => (dispatch) => {
  dispatch(updateProfileRequest(formData));
  UserService.updateProfile(formData).then(
    (res) => {
      dispatch(loginUserSuccess(res.data));
      saveUser(res.data);
    },
    (error) => {
      dispatch(updateProfileFailure({ error: error.response }));
    }
  );
};
