/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
const STORY_STATUS_KEY = {
  review: "Review",
  rework: "Rework",
  finished: "Finished",
  abandoned: "Abandoned",
  selected: "Selected",
};

const STORY_STATUS = [
  { value: STORY_STATUS_KEY.review, label: "Review" },
  { value: STORY_STATUS_KEY.rework, label: "Rework" },
  { value: STORY_STATUS_KEY.finished, label: "Finished" },
  { value: STORY_STATUS_KEY.abandoned, label: "Abandoned" },
];

export { STORY_STATUS, STORY_STATUS_KEY };
