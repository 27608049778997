/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { ROLES, userIs } from "../../../constants/Roles";
import Helpers from "../../../utils/helpers";

const View = (props) => {
  const { story } = props;
  return (
    <Fragment>
      <div className="container mt-5">
        {story && (
          <div className="card bg-light  p-4 border rounded">
            {userIs(ROLES.ADMIN) && (
              <div
                className="details"
                style={{ display: "flex", padding: "5px 20px" }}
              >
                <h4>
                  <Link
                    className="text-decoration-none"
                    to={`/internal/stories/${story.id}`}
                  >
                    {story.title}
                  </Link>
                </h4>
              </div>
            )}

            <p style={{ padding: "0px 20px" }}>
              {Helpers.convertMultiLine(story.body)}
            </p>

            <div className="d-flex flex-column">
              <button type="button" class="btn btn-success mb-2">
                {story.selected_assessment?.correctVariant}
              </button>
              <button type="button" class="btn btn-primary mb-2">
                {story.selected_assessment?.misdirectionVariant1}
              </button>
              <button type="button" class="btn btn-primary mb-2">
                {story.selected_assessment?.misdirectionVariant2}
              </button>
              <div className="border p-2 m-2">
                {story.selected_assessment?.explanation}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default View;
