/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import PublishedList from "../components/stories/published/PublishedView";

const Published = () => (
  <div className="container my-5">
    <div className="d-flex justify-content-between">
      <h1>Published</h1>
    </div>
    <PublishedList />
  </div>
);

export default Published;
