/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router";
import { ROLES, userIs } from "../../../constants/Roles";
import { STORY_STATUS } from "../../../constants/StoryStatus";
import AssesmentService from "../../../services/AssesmentService";
import UserService from "../../../services/UserService";
import { MySelect } from "../../form-fields/Select";
import AssesmentCardFrom from "./SingleAssessmentCard";

const queryString = require("query-string");
const StoriesList = ({ status, sortOrder, author, withSearch }) => {
  const [storyQuery, setQuery] = useState({});
  const [authors, setAuthors] = useState([]);
  const storyOptions = STORY_STATUS.map((i) => (
    <option value={i.value} label={i.label}></option>
  ));
  const authorOptions = authors.map((i) => (
    <option value={i.id} label={i.name}></option>
  ));
  status = storyQuery.status ? storyQuery.status : status;
  author = storyQuery.author ? storyQuery.author : author;
  const isSelected = storyQuery.isSelected ? storyQuery.isSelected : "";
  const fetchAuthors = () => {
    UserService.fetchAuthors(ROLES.PSYCHOLOGIST).then((res) => {
      setAuthors(res.data);
    });
  };

  useEffect(() => {
    if (withSearch) {
      userIs(ROLES.ADMIN) && fetchAuthors();
    }
  }, []);

  const fetchAll = ({ pageParam }) => {
    return AssesmentService.fetchFiltered({
      startAfter: pageParam,
      status: status,
      sortOrder: sortOrder,
      author: author,
      isSelected: isSelected,
    });
  };
  const history = useHistory();
  const { isLoading, error, isFetching, data, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ["filteredAssessments", status, author, sortOrder, isSelected],
      fetchAll,
      {
        keepPreviousData: true,
        onSuccess: (response) => {
          let assessmentArray = [];
          response.pages.map((i) =>
            i.data.assessments.map((j) => assessmentArray.push(j))
          );
          //setStories(assessmentArray);
        },
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.data.assessments.length) return undefined;
          else
            return lastPage.data.assessments[
              lastPage.data.assessments.length - 1
            ].id;
        },
        getPreviousPageParam: (lastPage, pages) => {
          if (!lastPage.data.length) return undefined;
          else
            return lastPage.data.assessments[
              lastPage.data.assessments.length - 1
            ].id;
        },
      }
    );
  let allAssessments = [];

  data?.pages?.map((i) =>
    i.data.assessments.map((j) => allAssessments.push(j))
  );
  const assessmentList = allAssessments
    ? allAssessments.map((assesment) => (
        <AssesmentCardFrom
          key={assesment.id}
          assesment={assesment}
          story={assesment.story}
        />
      ))
    : "";

  const onSearch = (qry) => {
    let string = queryString.stringify(qry);
    history.replace("/internal/assessments?" + string);
  };

  useEffect(() => {
    let qry = queryString.parse(history.location.search);

    setQuery(qry);
  }, [history.location.search]);

  return (
    <>
      {withSearch && (
        <div className="d-flex justify-content-end">
          <Formik
            initialValues={{
              status: status,
              author: author,
              isSelected: isSelected,
            }}
            enableReinitialize
            onSubmit={async (values) => {
              onSearch(values);
              // updateUser(values);
            }}
          >
            {(formikProps) => (
              <Form className="d-flex flex-row w-100 justify-content-end">
                <div className="w-100 me-4">
                  <MySelect
                    name="status"
                    label="Status"
                    placeholder="User Status"
                    className="form-control "
                  >
                    <option value="" key="">
                      All
                    </option>
                    {storyOptions}
                  </MySelect>
                </div>

                {userIs(ROLES.ADMIN) && (
                  <div className="w-100 me-4">
                    <MySelect
                      name="author"
                      label="author"
                      placeholder="User Status"
                      className="form-control"
                    >
                      <option key={"1"} value={""}>
                        {" "}
                        {"All"}
                      </option>
                      {authorOptions}
                    </MySelect>
                  </div>
                )}
                {userIs(ROLES.ADMIN) && (
                  <div className="w-100 me-4">
                    <MySelect
                      name="isSelected"
                      label="selected"
                      placeholder="Selection"
                      className="form-control"
                    >
                      <option key={"1"} value={""}>
                        {" "}
                        {"Any"}
                      </option>
                      <option key={"2"} value={true}>
                        {" "}
                        {"Yes"}
                      </option>
                      <option key={"3"} value={false}>
                        {" "}
                        {"No"}
                      </option>
                    </MySelect>
                  </div>
                )}
                <div className="d-flex justify-content-between mt-3 h-20">
                  <button
                    disabled={formikProps.isSubmitting || !formikProps.isValid}
                    className="btn btn-outline-primary px-5"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      <div className="my-3 list-group">
        <InfiniteScroll
          dataLength={allAssessments.length} //This is important field to render the next data
          next={() => fetchNextPage()}
          hasMore={hasNextPage}
          loading={isLoading}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              {isLoading || isFetching ? (
                <b>Loading...</b>
              ) : (
                <b>No more assessments available</b>
              )}
            </p>
          }
        >
          {assessmentList}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default StoriesList;
