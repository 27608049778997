/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MyTextArea } from "../../../form-fields/TextArea";
import { MyTextInput } from "../../../form-fields/TextInput";
import { MySelect } from "./../../../form-fields/Select";
const CreateCollectionForm = ({
  handleSubmit,
  handleClose,
  isCreating,
  topics,
}) => {
  const options = topics?.map((topic) => (
    <option key={topic.id} value={topic.id}>
      {topic.title}
    </option>
  ));
  return (
    <div>
      <Formik
        initialValues={{
          title: "",
          description: "",
          topic: "",
        }}
        validationSchema={Yup.object({
          title: Yup.string().required(),
          topic: Yup.string().required(),
          description: Yup.string().required(),
        })}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(formikProps) => (
          <Form>
            <MyTextInput
              name="title"
              label="Title"
              placeholder="Collection title"
              className="form-control"
            />
            <MySelect
              name="topic"
              label="Topic"
              plasceholder="Select topic"
              className="form-select"
            >
              <option value="">Select a topic</option>
              {options}
            </MySelect>
            <MyTextArea
              name="description"
              label="Description"
              placeholder="Collection description"
              className="form-control"
            />
            <div className="d-flex justify-content-between mt-3">
              <button
                type="button"
                className="btn btn-outline-secondary px-5"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                disabled={
                  formikProps.isSubmitting || !formikProps.isValid || isCreating
                }
                className="btn btn-outline-primary px-5"
                type="submit"
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateCollectionForm;
