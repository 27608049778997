/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import HeatMap from "@uiw/react-heat-map";
import Tooltip from "@uiw/react-tooltip";
import moment from "moment";

const WritersGraph = (props) => {
  let countactivity = (activity) => {
    let total = 0;
    if (activity.new_submission) total += activity.new_submission;
    if (activity.Abandoned) total += activity.Abandoned;
    if (activity.new_version) total += activity.new_version;
    if (activity.comment) total += activity.comment;

    return total;
  };

  let valuesNew = props.values?.map((i) => {
    return {
      ...i,
      date: new Date(i.date._seconds * 1000),
      count: countactivity(i),
    };
  });

  var d = new Date();
  d.setMonth(d.getMonth() - 10);
  return (
    <div className="mt-4">
      <center>
        <HeatMap
          width={"100%"}
          height={"100%"}
          value={valuesNew}
          rectSize={16}
          rectRender={(props, data) => {
            return (
              <Tooltip
                key={props.key}
                placement="top"
                content={
                  <div>
                    {`comment: ${data.comment || 0} , new submissions: ${
                      data.new_submission || 0
                    } , new version : ${data.new_version || 0} , abandoned: ${
                      data.Abandoned || 0
                    } on ${moment(data.date).format("LL")}`}
                  </div>
                }
              >
                <rect {...props} />
              </Tooltip>
            );
          }}
          startDate={d}
        />
      </center>
    </div>
  );
};

const PsychologistGraph = (props) => {
  let countactivity = (activity) => {
    let total = 0;
    if (activity.new_assessment_submission)
      total += activity.new_assessment_submission;
    if (activity.Abandoned_assessment) total += activity.Abandoned_assessment;
    if (activity.new_version_assessment)
      total += activity.new_version_assessment;
    if (activity.comment) total += activity.comment;

    return total;
  };

  let valuesNew = props.values?.map((i) => {
    return {
      ...i,
      date: new Date(i.date._seconds * 1000),
      count: countactivity(i),
    };
  });

  var d = new Date();
  d.setMonth(d.getMonth() - 10);
  return (
    <div className="mt-4">
      <center>
        <HeatMap
          width={"100%"}
          height={"100%"}
          value={valuesNew}
          rectSize={16}
          rectRender={(props, data) => {
            return (
              <Tooltip
                key={props.key}
                placement="top"
                content={
                  <div>
                    {`comment: ${data.comment || 0} , new assessments: ${
                      data.new_assessment_submission || 0
                    } , new version : ${data.new_version || 0} , abandoned: ${
                      data.Abandoned_assessment || 0
                    } on ${moment(data.date).format("LL")}`}
                  </div>
                }
              >
                <rect {...props} />
              </Tooltip>
            );
          }}
          startDate={d}
        />
      </center>
    </div>
  );
};

export { WritersGraph, PsychologistGraph };
