/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useEffect, useState } from "react";
import Linkify from "react-linkify";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ROLES, userIs } from "../../../../constants/Roles";
import { STORY_STATUS_KEY } from "../../../../constants/StoryStatus";
import UserService from "../../../../services/UserService";
import Helpers from "../../../../utils/helpers";
import { Error } from "../../../form-fields/Error";
import StoriesListByTopic from "../../../stories/FilterView";
import EditTopic from "../edit/Edit";
import TopicService from "./../../../../services/TopicService";

const ViewTopic = () => {
  const [topic, setTopic] = useState(null);
  const [error, setError] = useState(null);
  const [authors, setAuthors] = useState([]);
  const [showEditTopic, setShowEditTopic] = useState(false);
  const params = useParams();
  const user = Helpers.getUser();
  const handleError = (error) => {
    setError(Helpers.errorCreator(error));
  };

  const fetchTopic = ({ queryKey }) => {
    const { id } = queryKey[1];
    return TopicService.fetchOne(id);
  };
  const hideEditTopicModal = () => {
    setShowEditTopic(false);
  };
  useEffect(() => {
    if (userIs(ROLES.ADMIN)) fetchAuthors();
  }, []);
  const { isLoading, isFetching } = useQuery(
    ["topic", { id: params.id }],
    fetchTopic,
    {
      onSuccess: (response) => {
        setTopic(response.data);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );
  const fetchAuthors = () => {
    UserService.fetchAuthors().then((res) => {
      setAuthors(res.data);
    });
  };

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <>
      <div className="container my-5">
        <Error error={error} />
        {topic ? (
          <div className="card bg-light">
            <div className="card-header d-flex justify-content-between">
              {userIs(ROLES.ADMIN) && (
                <div
                  className="status text-lowercase border-2 text-warning text-body border px-2 rounded-1 border-danger bg-white"
                  style={{ height: 30 }}
                >
                  {topic.status}
                </div>
              )}

              <div>
                {userIs(ROLES.ADMIN) && (
                  <a
                    className="btn btn-outline-primary me-4"
                    onClick={() => setShowEditTopic(true)}
                  >
                    Edit
                  </a>
                )}
                {(userIs(ROLES.ADMIN) ||
                  (userIs(ROLES.WRITER) && topic.status !== "INACTIVE") ||
                  topic.assigned_writers?.includes(user.id)) && (
                  <Link
                    className="btn btn-primary"
                    type="button"
                    to={`/internal/topics/${topic.id}/create-story`}
                  >
                    Submit a story
                  </Link>
                )}
              </div>
            </div>
            <div className="card-body">
              <h5 className="card-title flex-grow-1">{topic.title}</h5>
              <p>
                <Linkify componentDecorator={componentDecorator}>
                  {Helpers.convertMultiLine(topic.description)}
                </Linkify>
              </p>
              {userIs(ROLES.ADMIN) && topic?.stats ? (
                <div className="d-flex justify-content-around pt-2 pb-2">
                  <Link
                    to={`/internal/stories?topic=${topic.id}&status=${STORY_STATUS_KEY.review}`}
                  >
                    Review : {topic.stats[STORY_STATUS_KEY.review] || 0}
                  </Link>
                  <Link
                    to={`/internal/stories?topic=${topic.id}&status=${STORY_STATUS_KEY.rework}`}
                  >
                    Rework : {topic.stats[STORY_STATUS_KEY.rework] || 0}
                  </Link>
                  <Link
                    to={`/internal/stories?topic=${topic.id}&status=${STORY_STATUS_KEY.finished}`}
                  >
                    Finished : {topic.stats[STORY_STATUS_KEY.finished] || 0}
                  </Link>
                  <Link
                    to={`/internal/stories?topic=${topic.id}&status=${STORY_STATUS_KEY.abandoned}`}
                  >
                    Abandoned : {topic.stats[STORY_STATUS_KEY.abandoned] || 0}
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="card-footer d-flex justify-content-end text-secondary text-link px-2">
              {` Created ${Helpers.convertTime(topic.created_at)}`} &nbsp;
              &nbsp;
              {`Last updated ${Helpers.convertTime(topic.updated_at)}`}
            </div>
          </div>
        ) : (
          ""
        )}
        <EditTopic
          show={showEditTopic}
          topic={topic}
          invalidate={"topic"}
          authors={authors}
          handleClose={hideEditTopicModal}
        />
        <StoriesListByTopic topic={params.id} />
      </div>
    </>
  );
};

export default ViewTopic;
