/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
const longestSubstring = (a, b) => {
  let indexA = 0,
    indexB = b.length,
    size = 0;
  for (let ai = 0; ai < a.length - size; ai++) {
    for (let bi = 0; bi < b.length - size; bi++) {
      for (
        let i = 0;
        i < Math.min(a.length - ai, b.length - bi) && a[ai + i] === b[bi + i];
        i++
      ) {
        if (i >= size) {
          size = i + 1;
          indexA = ai;
          indexB = bi;
        }
      }
    }
  }
  return {
    common: a.substring(indexA, indexA + size),
    firstLeft: a.substring(0, indexA),
    firstRight: a.substring(indexA + size),
    secondLeft: b.substring(0, indexB),
    secondRight: b.substring(indexB + size),
  };
};
const stringDiff = (before, after) => {
  if (
    typeof before !== "string" ||
    typeof after !== "string" ||
    before.length + after.length === 0
  ) {
    return [];
  }
  if (before.length === 0) {
    return [{ kind: "+", text: after }];
  }
  if (after.length === 0) {
    return [{ kind: "-", text: before }];
  }
  const res = longestSubstring(before, after);
  const commonTextArray = [];
  if (res.common.length > 0) {
    commonTextArray.push({ kind: "=", text: res.common });
  }
  return stringDiff(res.firstLeft, res.secondLeft).concat(
    commonTextArray,
    stringDiff(res.firstRight, res.secondRight)
  );
};

export default stringDiff;
