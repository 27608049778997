/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Fragment, useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useQuery, useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";
import { ROLES, userIs } from "../../../constants/Roles";
import { STORY_STATUS_KEY } from "../../../constants/StoryStatus";
import CollectionService from "../../../services/CollectionService";
import CommentService from "../../../services/CommentService";
import StoryService from "../../../services/StoryService";
import Helpers from "../../../utils/helpers";
import StoryDiff from "../../../utils/StrigDiff";
import CommentsList from "../../comments/List";
import { Error } from "../../form-fields/Error";
import Loader from "../../utils/Loader";
import ChangeCollection from "./ChangeCollection";
import ChangeStatus from "./ChangeStatus";
import UpdateTopic from "./ChangeTopic";
import ChangeView from "./ChangeView";
import SubmitStory from "./SubmitStory";

const View = (props) => {
  const [story, setStory] = useState(null);
  const [error, setError] = useState();
  const [audio, setAudio] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storyChangeView, setStoryChangeView] = useState(false);
  const [comments, setComments] = useState([]);
  const [showSubmitVersionModal, setShowSubmitModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const [showTopicModal, setShowTopicModal] = useState(false);
  const [collections, setCollections] = useState([]);
  const params = useParams();
  const queryClient = useQueryClient();
  const storyId = params.id;
  const handleError = (error) => {
    setError(Helpers.errorCreator(error));
  };

  const fetchStory = () => {
    return StoryService.fetchOne(storyId);
  };
  const fetchComments = () => {
    return CommentService.getCommentsByStory(storyId);
  };

  const fetchAllCollections = () => {
    return CollectionService.fetchAll();
  };

  useQuery(["collections"], fetchAllCollections, {
    onSuccess: (response) => {
      setCollections(response.data);
    },
    onError: (error) => {
      setError(Helpers.errorCreator(error));
    },
  });

  const updateStatus = (value) => {
    return new Promise((resolve, reject) => {
      StoryService.updateStatus(storyId, {
        status: userIs(ROLES.ADMIN) ? value.status : STORY_STATUS_KEY.abandoned,
      })
        .then((res) => {
          resolve(res);
          queryClient.invalidateQueries(["stories", { storyId: storyId }]);
          hideStatusModal();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const updateCollection = (value) => {
    return new Promise((resolve, reject) => {
      StoryService.updateCollection(storyId, {
        collection: value.collection,
      })
        .then((res) => {
          resolve(res);
          queryClient.invalidateQueries(["stories", { storyId: storyId }]);
          setShowCollectionModal(false);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const { isLoading, isFetching } = useQuery(
    ["stories", { storyId: storyId }],
    fetchStory,
    {
      onSuccess: (response) => {
        setStory(response.data);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );
  const hideCreateTopicModal = (data = null) => {
    if (data) {
      setStory({ ...story, ...data });
    }
    setShowSubmitModal(false);
  };
  const hideStatusModal = () => {
    setShowStatusModal(false);
  };
  const openCreateTopicModal = () => {
    setShowSubmitModal(true);
  };

  const openChangeStatusModal = () => {
    setShowStatusModal(true);
  };

  useQuery(["comments", { storyId: storyId }], fetchComments, {
    onSuccess: (response) => {
      setComments(response.data);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const wordCount = story?.body ? Helpers.getWordCount(story?.body) : 0;

  const fetchAudioData = async () => {
    setLoading(true);
    if (audio) URL.revokeObjectURL(audio);
    var audio = document.getElementById("myAudioElement");
    StoryService.fetchVoice(storyId)

      .then((myBlob) => {
        setLoading(false);
        const mp3 = new Blob([myBlob.data], { type: "audio/mp3" });
        const url = window.URL.createObjectURL(mp3);

        setAudio(url);
      })
      .catch((error) => {
        handleError("Unable to load audio, Please try again");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (story?.status === STORY_STATUS_KEY.review) fetchAudioData();
  }, [storyId, story?.status]);

  const storyPreviousValue = story?.change_log
    ?.filter((i) => i.event_type === "update_event")
    ?.flatMap((i) => i.cahnges.field_changes)
    ?.filter((i) => i.field === "body")
    ?.map((i) => i.oldValue)
    ?.splice(-1)?.[0];

  const storyDiff = StoryDiff(storyPreviousValue, story?.body);

  return (
    <Fragment>
      <Loader show={isLoading || isFetching} />
      <div className="container mt-5">
        <Error error={error} />
        {story && (
          <div className="card bg-light  p-4 border rounded">
            <div
              className="details d-flex justify-content-between"
              style={{ display: "flex", padding: "5px 20px" }}
            >
              <div className="d-flex">
                {userIs(ROLES.ADMIN, ROLES.WRITER) && <h4>{story.title}</h4>}
                <span
                  className="text-bold small text-secondary text-link"
                  style={{ marginLeft: "15px", marginTop: "6px" }}
                >
                  {Helpers.convertTime(story.updated_at)}
                </span>
                {story.author ? (
                  <span style={{ marginLeft: "15px", marginTop: "8px" }}>
                    <h6>
                      {userIs(ROLES.ADMIN) && (
                        <Link
                          className="text-decoration-none"
                          to={`/internal/profile/${story.author.id}`}
                        >
                          {story.author.name}
                        </Link>
                      )}
                      {userIs(ROLES.WRITER) && story.author.name}
                    </h6>
                  </span>
                ) : (
                  ""
                )}
              </div>

              {userIs(ROLES.ADMIN) && (
                <div>
                  <h6
                    className="btn text-primary"
                    onClick={() => setShowTopicModal(true)}
                  >
                    Change Topic
                  </h6>
                  <h6
                    className="btn text-primary ms-2"
                    onClick={() => setShowCollectionModal(true)}
                  >
                    {story.collection.title
                      ? story.collection.title
                      : story.status === STORY_STATUS_KEY.finished
                      ? "Add to a collection"
                      : ""}
                  </h6>
                </div>
              )}
            </div>

            <h6 style={{ padding: "0px 20px" }}>
              on{" "}
              {(userIs(ROLES.ADMIN, ROLES.WRITER) && (
                <Link
                  className="text-decoration-none"
                  to={`/internal/topics/${story.topic._path.segments[1]}`}
                >
                  {story.topicTitle}
                </Link>
              )) ||
                story.topicTitle}
            </h6>
            <div className="d-flex m-2  align-items-center">
              {story.status && (
                <div className="status text-lowercase m-2 mt-0 border-2 text-info text-body border px-2 rounded-1 border-info bg-white align-items-center h-50 ">
                  {story.status}
                </div>
              )}

              {story.selected_assessment && userIs(ROLES.ADMIN) && (
                <div className="status text-lowercase m-2 mt-0 border-2 text-info text-body border px-2 rounded-1 border-info bg-white align-items-center h-50 ">
                  assessed
                </div>
              )}
            </div>
            <p style={{ padding: "0px 20px" }}>
              {Helpers.convertMultiLine(story.body)}
            </p>

            <div className=" m-2 mb-4 container">
              {(userIs(ROLES.ADMIN) ||
                story.status !== STORY_STATUS_KEY.finished) && (
                <button
                  className="btn btn-primary mt-2"
                  onClick={openCreateTopicModal}
                >
                  Submit next version
                </button>
              )}
              {userIs(ROLES.ADMIN) &&
                story.status === STORY_STATUS_KEY.finished && (
                  <button
                    className="btn btn-primary ms-2 mt-2"
                    onClick={() =>
                      props.history.push(
                        `/internal/stories/${story.id}/assesments`
                      )
                    }
                  >
                    Assessments
                  </button>
                )}

              {userIs(ROLES.WRITER) &&
                story.status === STORY_STATUS_KEY.rework && (
                  <button
                    className="btn btn-danger ms-2 mt-2"
                    onClick={() => updateStatus({})}
                  >
                    Mark as Abandoned
                  </button>
                )}
              {userIs(ROLES.ADMIN) && (
                <button
                  className="btn btn-danger ms-2 mt-2"
                  onClick={() => openChangeStatusModal()}
                >
                  Change Status
                </button>
              )}
              <span
                className="ms-3 fw-bold fs-6 me-2"
                style={{ color: Helpers.getCountColor(wordCount) }}
              >
                {wordCount} words
              </span>
              {userIs(ROLES.ADMIN) && (
                <>
                  {audio && !loading ? (
                    <AudioPlayer autoPlay src={audio} />
                  ) : (
                    <button
                      className="btn btn-secondary mt-2"
                      onClick={() => fetchAudioData()}
                    >
                      {loading ? (
                        <div className="spinner-border text-primary me-2">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        " Text to Speech"
                      )}
                    </button>
                  )}
                </>
              )}
              <Link
                to={`/internal/stories/${story.id}/assesments/${story?.selected_assessment?._path?.segments?.[1]}`}
              >
                {userIs(ROLES.ADMIN) && story?.selected_assessment && (
                  <button className="btn btn-primary ms-2 mt-2">
                    View selected assessment
                  </button>
                )}
              </Link>
              {storyPreviousValue && (
                <button
                  className="btn btn-primary ms-2 mt-2"
                  onClick={() => setStoryChangeView(true)}
                >
                  Recent changes
                </button>
              )}
            </div>
            <CommentsList comments={comments} story={story} />
          </div>
        )}
      </div>

      <SubmitStory
        show={showSubmitVersionModal}
        handleClose={hideCreateTopicModal}
        story={story}
      />
      <ChangeStatus
        show={showStatusModal}
        updateStatus={updateStatus}
        handleClose={hideStatusModal}
        story={story}
      />
      <ChangeCollection
        show={showCollectionModal}
        collections={collections}
        updateCollection={updateCollection}
        handleClose={() => setShowCollectionModal(false)}
        story={story}
      />
      <UpdateTopic
        show={showTopicModal}
        handleClose={() => setShowTopicModal(false)}
        story={story}
      />
      <ChangeView
        show={storyChangeView}
        storyPreviousValue={story?.storyPreviousValue ?? []}
        diff={storyDiff}
        StoryDiff={StoryDiff}
        currentBody={story?.body}
        handleClose={() => setStoryChangeView(false)}
      />
    </Fragment>
  );
};

export default View;
