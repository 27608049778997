/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import axios from "axios";
import { logout } from "../states/actions/userAction";
const apiUrl = process.env.REACT_APP_API_URL;

export const addJWTInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      const { origin } = new URL(config.url);
      const allowedOrigins = [apiUrl];

      let user = localStorage.getItem("lyflair_user");

      //if there is no user in local storage don't add a token
      if (!user) return config;
      user = JSON.parse(user);

      const token = user.accessToken;

      const hasOrigin = allowedOrigins.some((aOrigin) =>
        aOrigin.includes(origin)
      );

      if (hasOrigin) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const errorHandlerInterceptor = (dispatch) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if ([401].includes(error?.response?.status)) {
        dispatch(logout());
      }
      return Promise.reject(error);
    }
  );
};
