/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import CollectionService from "../../../../services/CollectionService";
import Helpers from "../../../../utils/helpers";
import { Error } from "../../../form-fields/Error";
import CreateCollectionForm from "./CreateForm";

const CreateCollection = ({ show, handleClose, topics }) => {
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (formData) => CollectionService.create(formData),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("collections");
        handleClose();
      },
      onError: (error) => {
        setError(Helpers.errorCreator(error));
      },
    }
  );

  const handleSubmit = async (formData) => {
    const data = { ...formData };
    mutation.mutate(data);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Collection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} />
        <CreateCollectionForm
          topics={topics}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          isCreating={mutation.isLoading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CreateCollection;
