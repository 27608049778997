/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Container, Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ROLES } from "../../constants/Roles";
import { logout } from "../../states/actions/userAction";

const { WRITER, ADMIN, PSYCHOLOGIST } = ROLES;

const Header = ({ logout, user }) => {
  const userRole = user?.payload?.role;

  return (
    <Navbar bg="primary" expand="md">
      <Container>
        <Navbar.Toggle
          className="text-white ms-2 me-3"
          style={{ color: "white" }}
          aria-controls="basic-navbar-nav"
        />
        <Link className="navbar-brand text-white" to="/internal">
          <Navbar.Brand className="navbar-brand text-white" href="#home">
            I'm gonna help
          </Navbar.Brand>
        </Link>
        <div className="d-flex ms-auto 	d-md-none ">
          <Link className="nav-link text-white" to="/internal/profile">
            Profile
          </Link>
          <button className="btn btn-light" onClick={logout}>
            Logout
          </button>
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ps-2 pt-2">
            <Link className="nav-link text-white" to="/internal">
              Home
            </Link>
            {[ADMIN].includes(userRole) && (
              <Link className="nav-link text-white" to="/internal/users">
                Users
              </Link>
            )}
            {[ADMIN, WRITER].includes(userRole) && (
              <>
                <Link className="nav-link text-white" to="/internal/topics">
                  Topics
                </Link>
              </>
            )}
            {[ADMIN, WRITER, PSYCHOLOGIST].includes(userRole) && (
              <>
                <Link className="nav-link text-white" to="/internal/stories">
                  Stories
                </Link>
              </>
            )}
            {[ADMIN].includes(userRole) && (
              <Link className="nav-link text-white" to="/internal/writers">
                Writers
              </Link>
            )}

            {[ADMIN].includes(userRole) && (
              <Link className="nav-link text-white" to="/internal/collections">
                Collections
              </Link>
            )}
            {[ADMIN, PSYCHOLOGIST].includes(userRole) && (
              <Link className="nav-link text-white" to="/internal/assessments">
                Assessments
              </Link>
            )}
            {[ADMIN].includes(userRole) && (
              <Link
                className="nav-link text-white"
                to="/internal/psychologists"
              >
                Psychologists
              </Link>
            )}
            {[PSYCHOLOGIST, ADMIN].includes(userRole) && (
              <Link
                className="nav-link text-white"
                to="/internal/published-stories"
              >
                Published
              </Link>
            )}
          </Nav>
        </Navbar.Collapse>
        <div className="ms-auto 	 	d-none d-md-flex ">
          <Link
            className="nav-link text-white px-4 py-2"
            to="/internal/profile"
          >
            Profile
          </Link>
          <button className="btn btn-light" onClick={logout}>
            Logout
          </button>
        </div>
      </Container>
    </Navbar>
  );
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Header);
