/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Fragment, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import AssesmentService from "../../../services/AssesmentService";
import CommentService from "../../../services/CommentService";
import Helpers from "../../../utils/helpers";
import CommentsList from "../../comments/List";
import { Error } from "../../form-fields/Error";
import Loader from "../../utils/Loader";
import ChangeStatus from "./ChangeStatus";
const queryString = require("query-string");
const View = (props) => {
  const [assesment, setAssessment] = useState(null);
  const [error, setError] = useState();
  const [stats, setStats] = useState({});
  const [currentAssessment, setcurrentAssessment] = useState("");
  const [comments, setComments] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const queryClient = useQueryClient();
  const history = useHistory();
  let qry = queryString.parse(history.location.search);
  const handleError = (error) => {
    setError(Helpers.errorCreator(error));
  };

  const fetchAssesments = () => {
    return AssesmentService.fetchFiltered({
      status: "Review",
      limit: 1,
      startAfter: currentAssessment,
    });
  };
  const fetchComments = () => {
    return CommentService.getCommentsByAssesment(assesment?.id);
  };

  useQuery(["comments", { storyId: assesment?.story?.id }], fetchComments, {
    onSuccess: (response) => {
      setComments(response.data);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const { isLoading, isFetching } = useQuery(
    ["assessments", currentAssessment],
    fetchAssesments,
    {
      onSuccess: (response) => {
        if (!response.data.assessments.length)
          if (qry?.isFused) {
            return props.history.push("/internal/focusedstories");
          } else {
            return props.history.push("/internal");
          }
        setAssessment(response.data.assessments[0]);
        setStats(response.data.allStats);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const hideStatusModal = () => {
    setShowStatusModal(false);
  };

  const requeueStatus = () => {
    return new Promise((resolve, reject) => {
      AssesmentService.requeueAssesment(assesment.id)
        .then((res) => {
          resolve(res);
          queryClient.invalidateQueries(["assessments", currentAssessment]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <Fragment>
      <Loader show={isLoading || isFetching} />
      <div className="container mt-5">
        <Error error={error} />
        {assesment && (
          <div className="card bg-light  p-4 border rounded">
            <div
              className="details"
              style={{ display: "flex", padding: "5px 20px" }}
            >
              <Link
                className="text-decoration-none"
                to={`/internal/stories/${assesment?.story.id}`}
              >
                <h4>{assesment?.story.title}</h4>
              </Link>
              <span
                className="text-bold small text-secondary text-link"
                style={{ marginLeft: "15px", marginTop: "6px" }}
              >
                {Helpers.convertTime(assesment?.story.updated_at)}
              </span>
              {assesment?.story.author ? (
                <span style={{ marginLeft: "15px", marginTop: "8px" }}>
                  <h6>
                    <Link
                      className="text-decoration-none"
                      to={`/internal/profile/${assesment?.story.author.id}`}
                    >
                      {` ${assesment?.story.author.name}`}
                    </Link>
                  </h6>
                </span>
              ) : (
                ""
              )}

              {assesment?.story.selected_assessment && (
                <div className="status text-lowercase m-2 mt-0 border-2 text-info text-body border px-2 rounded-1 border-info bg-white align-items-center h-50 ">
                  {"assessed"}
                </div>
              )}
            </div>

            <h6 style={{ padding: "0px 20px" }}>
              on
              <Link
                className="text-decoration-none"
                to={`/internal/topics/${assesment?.story.topic.id}`}
              >
                {" "}
                {assesment?.story?.topic?.title}
              </Link>
            </h6>
            <div className="d-flex m-2  align-items-center"></div>
            <p style={{ padding: "0px 20px" }}>
              {Helpers.convertMultiLine(assesment?.story.body)}
            </p>
            <h5 className="ms-4">
              <Link to={`/internal/stories/${assesment?.story.id}/assesments`}>
                {"<"}Assesments
              </Link>
            </h5>

            <div className="card   p-4 border rounded">
              <u>
                <h6 className="d-flex">
                  Assessment by{" "}
                  <Link
                    className="text-decoration-none ms-2"
                    to={`/internal/profile/${assesment.author.id}`}
                  >
                    {" "}
                    {assesment.author.name}
                  </Link>
                  <div className="status ms-2 text-lowercase border-2 text-info text-body border px-2 rounded-1 border-info bg-white">
                    {assesment.status}
                  </div>
                </h6>
              </u>
              <h6>Correct variant:</h6>
              <span>{assesment.correctVariant}</span>

              <hr />

              <h6>Misdirection variant 1:</h6>
              <span>{assesment.misdirectionVariant1}</span>

              <hr />

              <h6>Misdirection variant 2:</h6>
              <span>{assesment.misdirectionVariant2}</span>

              <hr />

              <h6>Explanation:</h6>
              <span>{assesment.explanation}</span>
              <hr />
              <h6>Note:</h6>
              <span>{assesment.note}</span>
            </div>

            <div className="mt-4">
              <CommentsList
                comments={comments}
                story={assesment?.story}
                withSkip
                type="assesment"
                stats={stats?.stats}
                handleSkip={() => setcurrentAssessment(assesment.id)}
                assesmentId={assesment.id}
                requeueStatus={requeueStatus}
              />
            </div>
          </div>
        )}
      </div>

      <ChangeStatus
        show={showStatusModal}
        handleClose={hideStatusModal}
        story={assesment?.story}
      />
    </Fragment>
  );
};

export default View;
