/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Link } from "react-router-dom";
import { Priority } from "../../constants/Priority";
import { ROLES, userIs } from "../../constants/Roles";
import { STORY_STATUS_KEY } from "../../constants/StoryStatus";
import Helpers from "./../../utils/helpers";

const Topic = ({ topic, openEditModal }) => {
  return (
    <div className="card my-2">
      <div className="card-header d-flex justify-content-between">
        <div className="d-flex gap-3">
          {topic.priority === Priority.find((i) => i.key === "High")?.value && (
            <div className="status text-lowercase border-2 text-info text-body border px-2 rounded-1 border-info bg-white">
              {" "}
              {"High Priority"}
            </div>
          )}

          {userIs(ROLES.ADMIN) ? (
            <>
              <div className="status text-lowercase border-2 text-warning text-body border px-2 rounded-1 border-danger bg-white">
                {topic.status}
              </div>
              <div>
                <button
                  className="btn btn-outline-secondary py-0"
                  onClick={() => openEditModal(topic)}
                >
                  Edit
                </button>
              </div>
              {topic.assigned_writers?.length ? (
                <div className="status text-primary text-body  ">
                  {topic.assigned_writers?.length + " assigned writer(s):"}
                  {topic?.assigned_writers_details.map((i) => {
                    return (
                      <Link to={"/internal/profile/" + i.id}>
                        <span className="ms-2">{i.name}</span>
                      </Link>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {userIs(ROLES.WRITER) && topic.isExclusive && (
            <div className="status text-lowercase border-2 text-warning text-body border px-2 rounded-1 border-danger bg-white">
              Assigned
            </div>
          )}
        </div>
        <div className="details">
          <span className="text-muted">{topic.creator.name}, </span>
          <span className="text-bold small text-secondary text-link">
            {Helpers.convertTime(topic.created_at)}
          </span>
        </div>
      </div>
      <div className="card-body">
        <h5 className="card-title m-0">
          <Link
            className="text-decoration-none text-1-line"
            to={"./topics/" + topic.id}
          >
            {topic.title}
          </Link>
        </h5>
        <p className="card-text text-3-line mt-2">{topic.description}</p>
      </div>
      {userIs(ROLES.ADMIN) && topic?.stats && (
        <div className="d-flex justify-content-around pt-2 pb-2">
          <Link
            to={`/internal/stories?topic=${topic.id}&status=${STORY_STATUS_KEY.review}`}
          >
            Review : {topic.stats[STORY_STATUS_KEY.review] || 0}
          </Link>
          <Link
            to={`/internal/stories?topic=${topic.id}&status=${STORY_STATUS_KEY.rework}`}
          >
            Rework : {topic.stats[STORY_STATUS_KEY.rework] || 0}
          </Link>
          <Link
            to={`/internal/stories?topic=${topic.id}&status=${STORY_STATUS_KEY.finished}`}
          >
            Finished : {topic.stats[STORY_STATUS_KEY.finished] || 0}
          </Link>
          <Link
            to={`/internal/stories?topic=${topic.id}&status=${STORY_STATUS_KEY.abandoned}`}
          >
            Abandoned : {topic.stats[STORY_STATUS_KEY.abandoned] || 0}
          </Link>
        </div>
      )}
    </div>
  );
};
export default Topic;
