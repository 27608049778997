/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ViewCollections from "../components/collections/collection/view/View";
import Assessments from "../components/stories/assesment/Assesment";
import AssessmentSingle from "../components/stories/assesment/AssesmentSingle";
import FocusedAssessment from "../components/stories/assesment/FocusedAssesment";
import SelectAssessment from "../components/stories/assesment/SelectAssesmentView";
import CreateStory from "../components/stories/create/Create";
import FocusedStory from "../components/stories/story/FocusedView";
import View from "../components/stories/story/View";
import ViewTopic from "../components/topics/topic/view/View";
import Login from "../components/user/Login";
import CreatePassword from "../components/user/password/CreatePassword";
import ForgotPassword from "../components/user/password/ForgotPassword";
import RecoverPassword from "../components/user/password/RecoverPassword";
import Profile from "../components/user/profile/Profile";
import Register from "../components/user/Register";
import ErrorBoundary from "../components/utils/ErrorBoundary";
import Header from "../components/utils/Header";
import { ROLES } from "../constants/Roles";
import AssessmentsPage from "../containers/Assessment";
import Published from "../containers/Published";
import { useTitle } from "../utils/useTitle";
import ProfileUser from "./../containers/Profile";
import Users from "./../containers/Users";
import Collections from "./Collections";
import Home from "./Home";
import Psychologists from "./Psychologists";
import StoriesList from "./Stories";
import Topics from "./Topics";
import Writers from "./Writers";

const { WRITER, ADMIN, PSYCHOLOGIST } = ROLES;

function App({ user }) {
  useTitle("I'm gonna help");

  const userRole = user?.payload?.role;
  const userRoutes = (
    <>
      <Header user={user}></Header>
      <Switch>
        <Route path="/internal" exact component={Home} />
        <Route path="/internal/profile" exact component={Profile} />
        {[ADMIN].includes(userRole) && (
          <Route path="/internal/profile/:id" exact component={ProfileUser} />
        )}
        {[ADMIN].includes(userRole) && (
          <Route path="/internal/users" component={Users} />
        )}
        {[ADMIN].includes(userRole) && (
          <Route path="/internal/writers" component={Writers} />
        )}
        {[ADMIN].includes(userRole) && (
          <Route path="/internal/psychologists" component={Psychologists} />
        )}
        {[WRITER, ADMIN].includes(userRole) && (
          <Route path="/internal/topics" exact component={Topics} />
        )}
        {[WRITER, ADMIN].includes(userRole) && (
          <Route exact path="/internal/topics/:id" component={ViewTopic} />
        )}
        {[WRITER, ADMIN].includes(userRole) && (
          <Route
            path="/internal/topics/:id/create-story"
            exact
            component={CreateStory}
          />
        )}
        {[WRITER, ADMIN].includes(userRole) && (
          <Route exact path="/internal/stories/:id" component={View} />
        )}
        {[ADMIN, PSYCHOLOGIST].includes(userRole) && (
          <Route
            exact
            path="/internal/stories/:id/assesments"
            component={Assessments}
          />
        )}
        {[ADMIN, PSYCHOLOGIST].includes(userRole) && (
          <Route
            exact
            path="/internal/stories/:id/assesments/:assesmentId"
            component={AssessmentSingle}
          />
        )}
        {[ADMIN].includes(userRole) && (
          <Route
            exact
            path="/internal/focusedstories"
            component={FocusedStory}
          />
        )}
        {[WRITER, ADMIN, PSYCHOLOGIST].includes(userRole) && (
          <Route path="/internal/stories" exact component={StoriesList} />
        )}
        {[ADMIN].includes(userRole) && (
          <Route path="/internal/collections" exact component={Collections} />
        )}
        {[ADMIN].includes(userRole) && (
          <Route
            path="/internal/collection/:id"
            exact
            component={ViewCollections}
          />
        )}
        {[ADMIN, PSYCHOLOGIST].includes(userRole) && (
          <Route
            path="/internal/assessments"
            exact
            component={AssessmentsPage}
          />
        )}
        {[ADMIN].includes(userRole) && (
          <Route
            exact
            path="/internal/focused-assessment"
            component={FocusedAssessment}
          />
        )}
        {[ADMIN].includes(userRole) && (
          <Route
            exact
            path="/internal/select-assessment"
            component={SelectAssessment}
          />
        )}
        {[ADMIN, PSYCHOLOGIST].includes(userRole) && (
          <Route
            exact
            path="/internal/published-stories"
            component={Published}
          />
        )}

        <Redirect to="/internal" />
      </Switch>
    </>
  );

  const guestRoutes = (
    <>
      <Switch>
        <Route path="/internal/register" component={Register} />
        <Route path="/internal/login" component={Login} />
        <Route path="/internal/forgot-password" component={ForgotPassword} />
        <Route path="/internal/reset-password" component={RecoverPassword} />
        <Route path="/internal/create-password" component={CreatePassword} />
        <Route path="/">
          <Redirect to="/internal/login"></Redirect>{" "}
        </Route>
      </Switch>
    </>
  );

  return (
    <div className="container-fluid px-0 mx-0">
      <ErrorBoundary>
        {user && user.payload && !user.error ? userRoutes : guestRoutes}
      </ErrorBoundary>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(App);
