/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { NOT_FOUND } from "../constants/ErrorStatusCodes";
TimeAgo.addDefaultLocale(en);

const Helpers = {
  hasRole: (role, userRoles) => {
    return userRoles?.some((rl) => rl === role);
  },
  getUser: () => {
    return JSON.parse(localStorage.getItem("lyflair_user"));
  },
  errorCreator: (errorRS) => {
    console.error(errorRS);
    const error = errorRS?.response?.data;
    console.error(error);
    if (error && error?.message) {
      return error;
    }
    if (error && !error?.message) {
      if (error?.status === NOT_FOUND) {
        return { message: "Not Found" };
      }
      return { message: "Something went wrong" };
    }
    return { message: "Something went wrong" };
  },
  convertTime: (time) => {
    var date = new Date(time._seconds * 1000 + time._nanoseconds / 1000000);
    const timeAgo = new TimeAgo("en-US");
    return timeAgo.format(date);
  },
  convertMultiLine: (string) => {
    return (
      <pre style={{ "white-space": "pre-wrap", fontFamily: "inherit" }}>
        {string}
      </pre>
    );
  },

  getWordCount: (string) => {
    return string.trim()
      ? string
          .replaceAll(/[^a-zA-Z0-9]{2,}/g, " ")
          .replaceAll(/^ /g, "")
          .replaceAll(/ $/g, "")
          .replaceAll(/[^ ]+/g, "").length + 1
      : 0;
  },
  getCountColor: (count) => {
    if (count <= 110) {
      return "green";
    } else if (count <= 140) {
      return "rgb(244,196,48)";
    } else if (count <= 160) {
      return "rgb(238,118,0)";
    } else {
      return "red";
    }
  },
};

export default Helpers;
