/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import {
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
} from "../../constants/ActionTypes";

const user = (state = null, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        payload: { ...action.payload },
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
        payload: action.payload ? { ...action.payload } : null,
      };

    case UPDATE_PROFILE_FAILURE:
      state.isLoading = false;
      state.error = action.payload;
      return state;
    case LOGOUT_USER: {
      return null;
    }
    default:
      return state;
  }
};

export default user;
