/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import CookieConsent from "react-cookie-consent";
export default function CookieConcent() {
  const onAccept = (acceptedByScrolling) => {};

  const onDecline = () => {};

  return (
    <CookieConsent
      enableDeclineButton
      cookieName="lyflairConcent"
      buttonText={"Accept"}
      onAccept={onAccept}
      disableStyles
      onDecline={onDecline}
      buttonClasses={"btn btn-primary"}
      declineButtonClasses={"btn btn-secondary"}
      declineButtonText={"Decline"}
      style={{
        alignItems: "baseline",
        background: "#353535",
        color: "white",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        left: "0",
        position: "fixed",
        width: "100%",
        zIndex: "999",
      }}
      declineButtonStyle={{ marginRight: 16 }}
      buttonStyle={{ marginRight: 16 }}
      contentStyle={{
        flex: "1 0 300px",
        margin: "15px",
      }}
    >
      Please accept cookies to continue using the site
    </CookieConsent>
  );
}
