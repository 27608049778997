/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Link } from "react-router-dom";
import { ROLES, userIs } from "../../../constants/Roles";
import Helpers from "../../../utils/helpers";

const ViewStory = ({ story }) => {
  return (
    <div className="card my-2">
      <div className="card-body">
        <h5 className="card-title flex-grow-1">
          <Link
            className="text-decoration-none"
            to={
              userIs(ROLES.PSYCHOLOGIST)
                ? `/internal/stories/${story.id}/assesments`
                : `/internal/stories/${story.id}`
            }
          >
            {userIs(ROLES.ADMIN, ROLES.WRITER) && story.title}
            {userIs(ROLES.PSYCHOLOGIST) &&
              story.body.substring(0, 35).trim().replace(/\W+$/, "") + "…"}
          </Link>
        </h5>
        {userIs(ROLES.ADMIN, ROLES.WRITER) && story?.topic?.title && (
          <h6 className="text-secondary">
            on &nbsp;
            <Link
              className="text-decoration-none"
              to={`/internal/topics/${story.topic.id}`}
            >
              {story?.topic?.title}
            </Link>
          </h6>
        )}
        {userIs(ROLES.ADMIN) && story?.collection && (
          <h6 className="text-secondary">
            part of &nbsp;
            <Link
              className="text-decoration-none"
              to={`/internal/collection/${story.collection.id}`}
            >
              {story?.collection?.title}
            </Link>
          </h6>
        )}
        <p>{Helpers.convertMultiLine(story.body)}</p>
      </div>
      {userIs(ROLES.ADMIN, ROLES.WRITER) && (
        <div
          className={`card-footer d-flex justify-content-${
            story.status ? "between" : "end"
          }`}
        >
          <div className={"d-flex"}>
            {userIs(ROLES.ADMIN, ROLES.WRITER) && (
              <div className="status text-lowercase border-2 text-info text-body border px-2 rounded-1 border-info bg-white">
                {story.status}
              </div>
            )}
            {userIs(ROLES.ADMIN) && story.selected_assessment && (
              <div className="status text-lowercase border-2 ms-2 text-info text-body border px-2 rounded-1 border-info bg-white">
                assessed
              </div>
            )}
            {userIs(ROLES.ADMIN, ROLES.WRITER) && (
              <div
                className="mt-1 ms-3"
                style={{
                  color: Helpers.getCountColor(
                    Helpers.getWordCount(story.body)
                  ),
                }}
              >
                {Helpers.getWordCount(story.body) + " words"}
              </div>
            )}

            {userIs(ROLES.ADMIN) && (
              <div className="mt-1 ms-3">
                <Link
                  className="text-decoration-none"
                  to={`/internal/stories/${story.id}/assesments`}
                >
                  assessments
                </Link>
              </div>
            )}
          </div>
          <span>
            {userIs(ROLES.ADMIN) && (
              <span className="text-muted">
                Written By:{" "}
                <Link
                  className="text-decoration-none"
                  to={`/internal/profile/${story.author.id}`}
                >
                  {story.author.name}
                </Link>
              </span>
            )}
            <span className="text-bold text-secondary text-link px-1">
              {Helpers.convertTime(story.updated_at)}
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default ViewStory;
