/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
export const LOGIN_USER_SUCCESS = "LOGIN_USER";
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_FAILURE = "LOGIN_USER_ERROR";
//login

export const LOGOUT_USER = "LOGOUT_USER";

//list users
export const LIST_USERS_REQUEST = "LIST_USERS_REQUEST";
export const LIST_USERS = "LIST_USERS";
export const LIST_USERS_FAILURE = "LIST_USERS_FAILURE";
//list users

//users
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";

//update user
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_LIST = "UPDATE_USER_LIST";

//create user
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";
export const CREATE_USER_SUCCESS = "CREATE_USER";

//create password
export const CREATE_PASSWORD_REQUEST = "CREATE_PASSWORD_REQUEST";
export const CREATE_PASSWORD_SUCCESS = "CREATE_PASSWORD_SUCCESS";
export const CREATE_PASSWORD_FAILURE = "CREATE_PASSWORD_FAILURE";
