/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Fragment, useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { ROLES, userIs } from "../../../constants/Roles";
import { STORY_STATUS_KEY } from "../../../constants/StoryStatus";
import CommentService from "../../../services/CommentService";
import StoryService from "../../../services/StoryService";
import Helpers from "../../../utils/helpers";
import StoryDiff from "../../../utils/StrigDiff";
import CommentsList from "../../comments/List";
import { Error } from "../../form-fields/Error";
import Loader from "../../utils/Loader";
import ChangeStatus from "./ChangeStatus";
import ChangeView from "./ChangeView";
import SubmitStory from "./SubmitStory";

const View = (props) => {
  const [story, setStory] = useState(null);
  const [error, setError] = useState();
  const [audio, setAudio] = useState(false);
  const [storyChangeView, setStoryChangeView] = useState(false);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentStory, setCurrentStory] = useState("");
  const [comments, setComments] = useState([]);
  const [showSubmitVersionModal, setShowSubmitModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const queryClient = useQueryClient();
  const handleError = (error) => {
    setError(Helpers.errorCreator(error));
  };

  const fetchStory = () => {
    return StoryService.fetchActive({
      status: "Review",
      limit: 1,
      startAfter: currentStory,
    });
  };

  const updateStatus = (value) => {
    return new Promise((resolve, reject) => {
      StoryService.updateStatus(story.id, {
        status: userIs(ROLES.ADMIN) ? value.status : STORY_STATUS_KEY.abandoned,
      })
        .then((res) => {
          resolve(res);
          queryClient.invalidateQueries(["stories", currentStory]);
          hideStatusModal();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const requeueStatus = () => {
    return new Promise((resolve, reject) => {
      StoryService.requeueStory(story.id)
        .then((res) => {
          resolve(res);
          queryClient.invalidateQueries(["stories", currentStory]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const { isLoading, isFetching } = useQuery(
    ["stories", currentStory],
    fetchStory,
    {
      onSuccess: (response) => {
        if (!response.data.stories.length)
          return props.history.push("/internal");
        setStory(response.data.stories[0]);
        setStats(response.data?.allStats?.stats);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );
  const hideCreateTopicModal = (data = null) => {
    if (data) {
      setStory({ ...story, ...data });
    }
    setShowSubmitModal(false);
  };
  const hideStatusModal = () => {
    setShowStatusModal(false);
  };
  const openCreateTopicModal = () => {
    setShowSubmitModal(true);
  };

  const openChangeStatusModal = () => {
    setShowStatusModal(true);
  };

  const wordCount = story?.body ? Helpers.getWordCount(story?.body) : 0;

  const fetchAudioData = async () => {
    setLoading(true);
    if (audio) URL.revokeObjectURL(audio);
    var audio = document.getElementById("myAudioElement");
    StoryService.fetchVoice(story.id)

      .then((myBlob) => {
        setLoading(false);
        const mp3 = new Blob([myBlob.data], { type: "audio/mp3" });
        const url = window.URL.createObjectURL(mp3);

        setAudio(url);
      })
      .catch((error) => {
        handleError("Unable to load audio, Please try again");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (story?.id) {
      CommentService.getCommentsByStory(story.id).then((comments) => {
        setComments(comments.data);
      });
    }
  }, [story]);

  const storyPreviousValue = story?.change_log
    ?.filter((i) => i.event_type === "update_event")
    ?.flatMap((i) => i.cahnges.field_changes)
    ?.filter((i) => i.field === "body")
    ?.map((i) => i.oldValue)
    ?.splice(-1)?.[0];

  const storyDiff = StoryDiff(storyPreviousValue, story?.body);

  return (
    <Fragment>
      <Loader show={isLoading || isFetching} />
      <div className="container mt-5">
        <Error error={error} />
        {story ? (
          <div className="card bg-light  p-4 border rounded">
            <div
              className="details"
              style={{ display: "flex", padding: "5px 20px" }}
            >
              <a
                href={`/internal/stories/${story.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <h4>{story.title}</h4>
              </a>
              <span
                className="text-bold small text-secondary text-link"
                style={{ marginLeft: "15px", marginTop: "6px" }}
              >
                {Helpers.convertTime(story.updated_at)}
              </span>
              {story.author ? (
                <span style={{ marginLeft: "15px", marginTop: "8px" }}>
                  <h6>
                    <Link
                      className="text-decoration-none"
                      to={`/internal/profile/${story.author.id}`}
                    >
                      {` ${story.author.name}`}
                    </Link>
                  </h6>
                </span>
              ) : (
                ""
              )}
            </div>

            <h6 style={{ padding: "0px 20px" }}>
              on
              <Link
                className="text-decoration-none"
                to={`/internal/topics/${story.topic.id}`}
              >
                {` ${story.topic.title}`}
              </Link>
            </h6>
            <div className="d-flex m-2  align-items-center">
              {story.status && (
                <div className="status text-lowercase m-2 mt-0 border-2 text-info text-body border px-2 rounded-1 border-info bg-white align-items-center h-50 ">
                  {story.status}
                </div>
              )}
            </div>
            <p style={{ padding: "0px 20px" }}>
              {Helpers.convertMultiLine(story.body)}
            </p>

            <div className="container m-2 mb-4 ">
              <button
                className="btn btn-primary mb-2"
                onClick={openCreateTopicModal}
              >
                Submit next version
              </button>
              {userIs(ROLES.WRITER) &&
                story.status === STORY_STATUS_KEY.rework && (
                  <button
                    className="btn btn-danger ms-2 mb-2"
                    onClick={() => updateStatus({})}
                  >
                    Mark as Abandoned
                  </button>
                )}
              {userIs(ROLES.ADMIN) && (
                <button
                  className="btn btn-danger ms-2 mb-2"
                  onClick={() => openChangeStatusModal()}
                >
                  Change Status
                </button>
              )}
              {storyDiff.length ? (
                <button
                  className="btn btn-danger ms-2 mb-2"
                  onClick={() => setStoryChangeView(true)}
                >
                  Recent changes
                </button>
              ) : (
                ""
              )}

              <span
                className="ms-3 fw-bold fs-6"
                style={{ color: Helpers.getCountColor(wordCount) }}
              >
                {wordCount} words
              </span>
              <div className="mt-4">
                {audio && !loading ? (
                  <AudioPlayer autoPlay src={audio} />
                ) : (
                  <button
                    className="btn btn-secondary d-flex align-items-center"
                    onClick={() => fetchAudioData()}
                  >
                    {loading ? (
                      <div className="spinner-border text-primary me-2">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      " Text to Speech"
                    )}
                  </button>
                )}
              </div>
            </div>
            <CommentsList
              withSkip
              comments={comments}
              story={story}
              stats={stats}
              handleSkip={() => setCurrentStory(story.id)}
              requeueStatus={requeueStatus}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <ChangeView
        show={storyChangeView}
        storyPreviousValue={story?.storyPreviousValue ?? []}
        StoryDiff={StoryDiff}
        handleClose={() => setStoryChangeView(false)}
      />
      <SubmitStory
        show={showSubmitVersionModal}
        handleClose={hideCreateTopicModal}
        story={story}
      />
      <ChangeStatus
        show={showStatusModal}
        updateStatus={updateStatus}
        handleClose={hideStatusModal}
        story={story}
      />
    </Fragment>
  );
};

export default View;
