/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import UserList from "../components/users/List";
import Loader from "../components/utils/Loader";
import { fetchAllUsers } from "../states/actions/manageUsersAction";

const Users = ({ fetchAllUsers, users }) => {
  const fetchUsers = useCallback(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  useEffect(fetchUsers, [fetchUsers]);

  return (
    <>
      <Loader show={users.isLoading} />
      <div className="container my-5">
        <UserList users={users} refetch={fetchUsers} />
      </div>{" "}
    </>
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps, { fetchAllUsers })(Users);
