/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import StoryService from "../../../services/StoryService";
import Helpers from "../../../utils/helpers";
import { Error } from "../../form-fields/Error";
import UpdateStoryForm from "./UpdateStoryForm";

const SubmitStory = (props) => {
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();
  const didFieldChange = (oldValue, newValue) => {
    if (oldValue === newValue) {
      return false;
    }
    return true;
  };
  const mutation = useMutation(
    (formData) => StoryService.submitNewStoryVersion(formData, props.story.id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("topics");

        props.handleClose(data.data.updatedStory);
      },
      onError: (error) => {
        setError(Helpers.errorCreator(error));
      },
    }
  );

  const handleSubmit = async (formData) => {
    const data = { ...formData };

    const versionChange = [];
    const didTitleChange = didFieldChange(props.story.title, data.title)
      ? {
          field: "title",
          oldValue: props.story.title,
          newValue: data.title,
        }
      : null;
    const didBodyChange = didFieldChange(props.story.body, data.body)
      ? {
          field: "body",
          oldValue: props.story.body,
          newValue: data.body,
        }
      : null;
    if (didTitleChange !== null) versionChange.push(didTitleChange);
    if (didBodyChange !== null) versionChange.push(didBodyChange);
    data.version_change = { field_changes: versionChange };
    if (versionChange.length <= 0) return;
    mutation.mutate(data);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      dialogClassName="modal-90w"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Submit next version</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} />
        <UpdateStoryForm
          handleSubmit={handleSubmit}
          handleClose={props.handleClose}
          isCreating={mutation.isLoading}
          story={props.story}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SubmitStory;
