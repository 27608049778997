/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router";
import UserService from "../../../services/UserService";
import { Error } from "../../form-fields/Error";
import Loader from "../../utils/Loader";
import CreatePasswordForm from "./CreatePasswordForm";

const CreatePassword = () => {
  let query = useSearchQuery();

  const mutation = useMutation(
    (passwordData) => UserService.createPassword(passwordData),
    {
      onSuccess: (data) => {
        history.push("/internal/login");
      },
      onError: (error) => {
        setError(error.response.data);
      },
    }
  );

  const history = useHistory();
  const createKey = query.get("createKey");
  const [error, setError] = useState(null);

  const handleSubmit = async (formData) => {
    const data = { ...formData, createKey };
    mutation.mutate(data);
  };

  return (
    <div>
      <Loader show={mutation.isLoading} />
      <div className="col col-md-4 col-lg-3 vh-100 mx-5 mx-md-auto  d-flex flex-column justify-content-center">
        <h1>Create Password</h1>
        <Error error={error} />
        <CreatePasswordForm
          handleSubmit={handleSubmit}
          btnText="Create"
          isLoading={mutation.isLoading}
        />
      </div>
    </div>
  );
};

function useSearchQuery() {
  return new URLSearchParams(useLocation().search);
}
export default CreatePassword;
