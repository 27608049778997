/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { resetPassword } from "../../../states/actions/userAction";
import { MyTextInput } from "../../form-fields/TextInput";

const ForgotPassword = () => {
  const history = useHistory();

  const resetUserPassword = async (values) => {
    try {
      await resetPassword(values);
      history.push("/internal/login");
    } catch (e) {}
  };

  return (
    <div className="col col-md-4 col-lg-3 vh-100 mx-5 mx-md-auto  d-flex flex-column justify-content-center">
      <h1>Reset Password</h1>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string().required("Email is required"),
        })}
        onSubmit={async (values) => {
          resetUserPassword(values);
        }}
      >
        {(formikProps) => (
          <Form>
            <MyTextInput
              className="form-control"
              name="email"
              type="email"
              placeholder="user@example.com"
              label="Email"
            />
            <div className="d-flex justify-content-between mt-3">
              <button
                disabled={
                  formikProps.isSubmitting ||
                  !formikProps.isValid ||
                  !formikProps.dirty
                }
                className="btn btn-primary flex-grow-1"
                type="submit"
              >
                Reset Password
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
