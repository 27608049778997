/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Fragment, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";
import { ROLES, userIs } from "../../../constants/Roles";
import { STORY_STATUS_KEY } from "../../../constants/StoryStatus";
import AssesmentService from "../../../services/AssesmentService";
import CommentService from "../../../services/CommentService";
import Helpers from "../../../utils/helpers";
import CommentsList from "../../comments/List";
import { Error } from "../../form-fields/Error";
import Loader from "../../utils/Loader";
import ChangeStatus from "./ChangeStatus";
import SubmitStory from "./SubmitNewVersion.jsx";
const View = () => {
  const [story, setStory] = useState(null);
  const [error, setError] = useState();
  const [isAssessmentDone, setAssessmentDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assesment, setAssesment] = useState([]);
  const [showSubmitVersionModal, setShowSubmitModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const params = useParams();
  const queryClient = useQueryClient();
  const storyId = params.id;

  const [comments, setComments] = useState([]);
  const handleError = (error) => {
    setError(Helpers.errorCreator(error));
  };

  const fetchAssesment = () => {
    return AssesmentService.fetchSingle(params.assesmentId);
  };
  const fetchComments = () => {
    return CommentService.getCommentsByAssesment(params.assesmentId);
  };

  const selectAssesment = () => {
    setLoading(true);
    return AssesmentService.selectAssesment(params.assesmentId)
      .then((res) => {
        queryClient.invalidateQueries([
          "assesments",
          { assesmentId: params.assesmentId },
        ]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const unselectAssesment = () => {
    setLoading(true);
    return AssesmentService.unselectAssesment(params.assesmentId)
      .then((res) => {
        queryClient.invalidateQueries([
          "assesments",
          { assesmentId: params.assesmentId },
        ]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const { isLoading, isFetching } = useQuery(
    ["assesments", { assesmentId: params.assesmentId }],
    fetchAssesment,
    {
      onSuccess: (response) => {
        setStory(response.data.story);
        setAssesment(response.data.assesment);
        setAssessmentDone(response.data.isAssessmentDone);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );
  const hideCreateTopicModal = () => {
    setShowSubmitModal(false);
  };
  useQuery(["comments", { storyId: storyId }], fetchComments, {
    onSuccess: (response) => {
      setComments(response.data);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const hideStatusModal = () => {
    setShowStatusModal(false);
  };

  const openChangeStatusModal = () => {
    setShowStatusModal(true);
  };

  const updateStatus = (value) => {
    return new Promise((resolve, reject) => {
      AssesmentService.updateStatus(params.assesmentId, {
        status: value.status,
      })
        .then((res) => {
          resolve(res);
          queryClient.invalidateQueries([
            "assesments",
            { assesmentId: params.assesmentId },
          ]);
          hideStatusModal();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  return (
    <Fragment>
      <Loader show={isLoading || isFetching || loading} />
      <div className="container mt-5">
        <Error error={error} />
        {story ? (
          <div className="card bg-light  p-4 border rounded">
            <div
              className="details"
              style={{
                display: "flex",
                padding: "5px 20px",
              }}
            >
              {userIs(ROLES.ADMIN) && (
                <Link
                  className="text-decoration-none"
                  to={`/internal/stories/${story.id}`}
                >
                  <h4>{story.title}</h4>
                </Link>
              )}
              <span
                className="text-bold small text-secondary text-link"
                style={{ marginLeft: "15px", marginTop: "6px" }}
              >
                {Helpers.convertTime(story.updated_at)}
              </span>
              {userIs(ROLES.ADMIN) && story.author ? (
                <span style={{ marginLeft: "15px", marginTop: "8px" }}>
                  <h6>
                    {userIs(ROLES.ADMIN) ? (
                      <Link
                        className="text-decoration-none"
                        to={`/internal/profile/${story.author.id}`}
                      >
                        {` ${story.author.name}`}
                      </Link>
                    ) : (
                      story.author.name
                    )}
                  </h6>
                </span>
              ) : (
                ""
              )}
              {story.selected_assessment && userIs(ROLES.ADMIN) && (
                <div className="status text-lowercase m-2 mt-0 border-2 text-info text-body border px-2 rounded-1 border-info bg-white align-items-center h-50 ">
                  assessed
                </div>
              )}
            </div>

            {userIs(ROLES.ADMIN) && (
              <h6 style={{ padding: "0px 20px" }}>
                on
                {userIs(ROLES.ADMIN) ? (
                  <Link
                    className="text-decoration-none"
                    to={`/internal/topics/${story.topic.id}`}
                  >
                    {` ${story?.topic?.title}`}
                  </Link>
                ) : (
                  story?.topic?.title
                )}
              </h6>
            )}
            <div className="d-flex m-2  align-items-center"></div>
            <p style={{ padding: "0px 20px" }}>
              {Helpers.convertMultiLine(story.body)}
            </p>
            <Link to={`/internal/stories/${story.id}/assesments`}>
              <h5 className="ms-4">{"<"}Assesments</h5>
            </Link>

            <div className="card   p-4 border rounded">
              <u>
                <h6 className="d-flex">
                  Assessment by{" "}
                  {userIs(ROLES.ADMIN) ? (
                    <Link
                      className="text-decoration-none ms-2"
                      to={`/internal/profile/${assesment.author.id}`}
                    >
                      {` ${assesment.author.name}`}
                    </Link>
                  ) : (
                    assesment.author.name
                  )}
                  <div className="status ms-2 text-lowercase border-2 text-info text-body border px-2 rounded-1 border-info bg-white">
                    {assesment.status}
                  </div>
                </h6>
              </u>
              <h6>Correct variant:</h6>
              <span>{assesment.correctVariant}</span>

              <hr />

              <h6>Misdirection variant 1:</h6>
              <span>{assesment.misdirectionVariant1}</span>

              <hr />

              <h6>Misdirection variant 2:</h6>
              <span>{assesment.misdirectionVariant2}</span>

              <hr />

              <h6>Explanation:</h6>
              <span>{assesment.explanation}</span>
              <hr />
              <h6>Note:</h6>
              <span>{assesment.note}</span>
            </div>

            <div className="d-flex m-2 mt-4 mb-4 align-items-center">
              <button
                className="btn btn-primary"
                onClick={() => setShowSubmitModal(true)}
              >
                Submit next version
              </button>

              {userIs(ROLES.ADMIN) && (
                <button
                  className="btn btn-danger ms-2"
                  onClick={() => openChangeStatusModal()}
                >
                  Change Status
                </button>
              )}

              {userIs(ROLES.ADMIN) &&
                story?.selected_assessment?._path?.segments?.[1] ===
                  params.assesmentId && (
                  <button
                    className="btn btn-primary ms-4"
                    onClick={() => unselectAssesment(assesment.id)}
                  >
                    Unselect assessment
                  </button>
                )}
              {userIs(ROLES.ADMIN) &&
                assesment.status === STORY_STATUS_KEY.finished &&
                story?.selected_assessment?._path?.segments?.[1] !==
                  params.assesmentId && (
                  <button
                    className="btn btn-primary ms-4"
                    onClick={() => selectAssesment(assesment.id)}
                  >
                    Select assessment
                  </button>
                )}
              {userIs(ROLES.ADMIN) &&
                story?.selected_assessment?._path?.segments?.[1] ===
                  params.assesmentId && (
                  <span className="ms-4 text-success border border-success">
                    selected assessment
                  </span>
                )}
            </div>

            <div className="mt-4">
              <CommentsList
                comments={comments}
                story={story}
                type="assesment"
                assesmentId={params.assesmentId}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <SubmitStory
        show={showSubmitVersionModal}
        handleClose={hideCreateTopicModal}
        assesment={assesment}
        assesmentId={params.assesmentId}
        story={story}
      />
      <ChangeStatus
        assesment={assesment}
        show={showStatusModal}
        updateStatus={updateStatus}
        handleClose={hideStatusModal}
        story={story}
      />
    </Fragment>
  );
};

export default View;
