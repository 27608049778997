/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import StoryList from "../components/stories/FilterView";
import { ROLES, userIs } from "../constants/Roles";

const Stories = () => (
  <div className="container my-5">
    <h1>Stories</h1>

    <StoryList withSearch={userIs(ROLES.ADMIN, ROLES.WRITER)} />
  </div>
);

export default Stories;
