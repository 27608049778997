/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ROLES, userIs } from "../../../constants/Roles";
import { Error } from "../../form-fields/Error";
import UpdateStatusForm from "./UpdateStatusForm";

const AssesmentStatusModal = (props) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const didFieldChange = (oldValue, newValue) => {
    if (oldValue === newValue) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (formData) => {
    const data = { ...formData };
    setIsLoading(true);
    if (
      !didFieldChange(props.story.status, data.status) &&
      userIs(ROLES.ADMIN)
    ) {
      props.handleClose();
      setIsLoading(false);
      return;
    }

    props.updateStatus(data).then(() => {
      setIsLoading(false);
    });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      dialogClassName="modal-90w"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} />
        <UpdateStatusForm
          handleSubmit={handleSubmit}
          handleClose={props.handleClose}
          story={props.story}
          assesment={props.assesment}
          didFieldChange={didFieldChange}
          isLoading={isLoading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AssesmentStatusModal;
