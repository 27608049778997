/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { updateUser } from "../../../states/actions/manageUsersAction";
import { Error } from "../../form-fields/Error";
import EditUserForm from "./EditForm";

const EditUser = ({
  show,
  handleClose,
  user,
  updateUser,
  onUpdate,
  isLoading,
  error,
}) => {
  const update = async (formData) => {
    onUpdate(formData);
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="position-relative">
          <Error error={error} />
          <EditUserForm
            user={user}
            handleClose={handleClose}
            updateUser={update}
            isLoading={isLoading}
          />
        </Modal.Body>
      </Modal>{" "}
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.users.isUserUpdating,
  error: state.users.updateUserError,
});
export default connect(mapStateToProps, { updateUser })(EditUser);
