/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Error } from "../form-fields/Error";
import Collection from "./Collection";
import CreateCollection from "./collection/create/Create";
import EditCollection from "./collection/edit/Edit";
import SyncButton from "./Sync";

const CollectionList = ({ collections, error, topics }) => {
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [collection, setCollection] = useState(null);
  const [showEditCollection, setShowEditCollection] = useState(false);

  const hideCreateCollectionModal = () => {
    setShowCreateCollection(false);
  };

  const openCreateCollectionModal = () => {
    setShowCreateCollection(true);
  };

  const hideEditCollectionModal = () => {
    setCollection(null);
    setShowEditCollection(false);
  };

  const openEditCollectionModal = (collection) => {
    setCollection(collection);
    setShowEditCollection(true);
  };

  if (!Array.isArray(collections)) {
    collections = [];
  }
  const collectionList =
    collections &&
    collections.map((cl) => (
      <Collection
        openEditModal={openEditCollectionModal}
        key={cl.id}
        collection={cl}
      />
    ));

  return (
    <>
      <h1>Collections</h1>
      <Error error={error} />

      <div className="d-flex my-3 gap-4 justify-content-end">
        <div className="d-flex gap-2 pt-2"></div>
        <SyncButton />
        <button className="btn btn-primary" onClick={openCreateCollectionModal}>
          Add New Collection
        </button>
      </div>

      <div className="my-3 list-group">{collectionList}</div>

      <>
        <CreateCollection
          topics={topics}
          show={showCreateCollection}
          handleClose={hideCreateCollectionModal}
        />
        <EditCollection
          topics={topics}
          show={showEditCollection}
          invalidate={"collections"}
          collection={collection}
          handleClose={hideEditCollectionModal}
        />
      </>
    </>
  );
};

export default CollectionList;
