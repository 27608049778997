/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../../form-fields/TextInput";

const CreatePasswordForm = ({ handleSubmit, btnText, isLoading }) => {
  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirmation: "",
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .required("Password is required")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
            `Password should have at least 8 charachters and
                             contain at least one lowercase letter, 
                             one uppercase letter and a digit`
          ),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Password Cofirmation is required"),
      })}
      onSubmit={async (values) => {
        handleSubmit(values);
      }}
    >
      {(formikProps) => (
        <Form>
          <MyTextInput
            className="form-control"
            name="password"
            type="password"
            placeholder="Password"
            label="Password"
          />
          <MyTextInput
            className="form-control"
            name="passwordConfirmation"
            type="password"
            label="Confirm password"
            placeholder="Confirm password"
          />
          <div className="d-flex justify-content-between mt-3">
            <button
              disabled={
                formikProps.isSubmitting || !formikProps.isValid || isLoading
              }
              className="btn btn-outline-primary flex-grow-1"
              type="submit"
            >
              {btnText}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreatePasswordForm;
