/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import axios from "axios";
const apiUrl = `${process.env.REACT_APP_API_URL}/topics`;

const TopicService = {
  fetchAll: (archived) => {
    return axios.get(`${apiUrl}`, {
      params: {
        archived: archived,
      },
    });
  },
  create: (formData) => {
    return axios.post(`${apiUrl}`, formData);
  },
  update: ({ id, data }) => {
    return axios.put(`${apiUrl}/${id}`, data);
  },
  fetchOne: (id) => {
    return axios.get(`${apiUrl}/${id}`);
  },
};

export default TopicService;
