/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import axios from "axios";

const StoryService = {
  create: (story) =>
    axios.post(`${process.env.REACT_APP_API_URL}/stories`, story),
  fetchStoriesByTopic: (topic) =>
    axios.get(`${process.env.REACT_APP_API_URL}/stories/fetch-by-topic`, {
      params: {
        topicId: topic,
      },
    }),
  fetchAll: () => axios.get(`${process.env.REACT_APP_API_URL}/stories`),
  fetchUserStories: (id) =>
    axios.get(`${process.env.REACT_APP_API_URL}/stories/user/${id}`),
  fetchActive: (qry) =>
    axios.get(`${process.env.REACT_APP_API_URL}/stories/all/withfilters`, {
      params: qry,
    }),
  fetchOne: (storyId) =>
    axios.get(`${process.env.REACT_APP_API_URL}/stories/${storyId}`),
  updateStatus: (storyId, status) =>
    axios.put(
      `${process.env.REACT_APP_API_URL}/stories/changeStoryStatus/${storyId}`,
      status
    ),
  updateCollection: (storyId, collection) =>
    axios.put(
      `${process.env.REACT_APP_API_URL}/stories/updateCollection/${storyId}`,
      collection
    ),
  submitNewStoryVersion: (story, storyId) =>
    axios.put(
      `${process.env.REACT_APP_API_URL}/stories/submitNewVersion/${storyId}`,
      story
    ),
  fetchVoice: (id) =>
    axios.get(`${process.env.REACT_APP_API_URL}/stories/${"getVoice"}/${id}`, {
      responseType: "blob",
    }),
  updateTopic: (storyId, topicId) =>
    axios.put(
      `${process.env.REACT_APP_API_URL}/stories/updateTopic/${storyId}`,
      {
        topic: topicId,
      }
    ),
  requeueStory: (id) =>
    axios.put(`${process.env.REACT_APP_API_URL}/stories/requeue/${id}`),
  fetchPsychologistStory: (qry) =>
    axios.get(`${process.env.REACT_APP_API_URL}/stories/all/psychologist`, {
      params: qry,
    }),
  fetchAllPublished: (qry) =>
    axios.get(`${process.env.REACT_APP_API_URL}/stories/all/published`, {
      params: qry,
    }),
};

export default StoryService;
