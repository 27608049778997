/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import axios from "axios";
const queryString = require("query-string");
const apiUrl = `${process.env.REACT_APP_API_URL}/assesment`;

const StoryService = {
  create: (data) => {
    return axios.post(`${apiUrl}/create`, data);
  },
  fetchAll: (id) => {
    return axios.get(`${apiUrl}/fetchAll/${id}`);
  },
  fetchSingle: (assesmentId) => {
    return axios.get(`${apiUrl}/${assesmentId}`);
  },
  updateStatus: (id, data) => {
    return axios.put(`${apiUrl}/changeStatus/${id}`, data);
  },
  selectAssesment: (id) => {
    return axios.put(`${apiUrl}/select/${id}`);
  },
  unselectAssesment: (id) => {
    return axios.put(`${apiUrl}/unselect/${id}`);
  },
  update: (id, body) => {
    return axios.put(`${apiUrl}/submitNewVersion/${id}`, body);
  },
  fetchFiltered: (qry) => {
    let string = queryString.stringify(qry);

    return new Promise((resolve, reject) => {
      axios.get(`${apiUrl}/all/withfilters?` + string).then((response) => {
        resolve(response);
      });
    });
  },
  requeueAssesment: (id) => {
    return axios.put(`${apiUrl}/${"requeue"}/${id}`);
  },
};

export default StoryService;
