/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ROLES, userIs } from "../../../constants/Roles";
import { MySelect } from "../../form-fields/Select";
import { MyTextArea } from "../../form-fields/TextArea";
import { MyTextInput } from "../../form-fields/TextInput";

const CreateStoryForm = ({
  authors,
  handleCancel,
  handleSubmit,
  isLoading,
}) => {
  const options = authors?.map((writer) => (
    <option key={writer.id} value={writer.id}>
      {writer.name}
    </option>
  ));

  return (
    <div className="col-12 col-md-8">
      <h5>Create a new story</h5>
      <Formik
        initialValues={{
          author: "",
          title: "",
          body: "",
        }}
        validationSchema={Yup.object({
          author: userIs(ROLES.ADMIN) ? Yup.string().required() : "",
          title: Yup.string().required(),
          body: Yup.string().required(),
        })}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form>
            {userIs(ROLES.ADMIN) ? (
              <MySelect
                name="author"
                label="Author"
                plasceholder="Select author"
                className="form-select"
              >
                <option value="">Select an author</option>
                {options}
              </MySelect>
            ) : (
              ""
            )}
            <MyTextInput
              name="title"
              label="Story title"
              plasceholder="Story title"
              className="form-control"
            />
            <MyTextArea
              name="body"
              label="Story body"
              rows={5}
              plasceholder="Write content"
              className="form-control"
            />
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-outline-primary px-5 mt-3"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-outline-primary px-5 mt-3"
                disabled={formik.isSubmitting || !formik.isValid || isLoading}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateStoryForm;
