/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { ROLES, userIs } from "../../../constants/Roles";
import Helpers from "../../../utils/helpers";

const View = (props) => {
  const { story, assesment } = props;

  return (
    <Fragment>
      <div className="container mt-5">
        {story && (
          <div className="card bg-light  p-4 border rounded">
            <div
              className="details"
              style={{ display: "flex", padding: "5px 20px" }}
            >
              <h4>
                {userIs(ROLES.ADMIN) && (
                  <Link
                    className="text-decoration-none"
                    to={`/internal/stories/${story.id}`}
                  >
                    {story.title}
                  </Link>
                )}
              </h4>
              <span
                className="text-bold small text-secondary text-link"
                style={{ marginLeft: "15px", marginTop: "6px" }}
              >
                {Helpers.convertTime(story.updated_at)}
              </span>
              {userIs(ROLES.ADMIN) && story.author && (
                <span style={{ marginLeft: "15px", marginTop: "8px" }}>
                  <h6>
                    <Link
                      className="text-decoration-none"
                      to={`/internal/profile/${story.author.id}`}
                    >
                      {" "}
                      {story.author.name}
                    </Link>
                  </h6>
                </span>
              )}
            </div>

            {userIs(ROLES.ADMIN) && (
              <h6 style={{ padding: "0px 20px" }}>
                on
                <Link
                  className="text-decoration-none"
                  to={`/internal/topics/${story.topic.id}`}
                >
                  {" "}
                  {story?.topic?.title}
                </Link>
              </h6>
            )}
            <div className="d-flex m-2  align-items-center"></div>
            <p style={{ padding: "0px 20px" }}>
              {Helpers.convertMultiLine(story.body)}
            </p>

            <div className="card   p-4 border rounded">
              <u>
                <h6 className="d-flex">
                  <Link
                    className="text-decoration-none"
                    to={`/internal/stories/${story.id}/assesments/${assesment.id}`}
                  >
                    Assessment by {` ${assesment.author.name}`}
                  </Link>
                  <div className="status ms-2 text-lowercase border-2 text-info text-body border px-2 rounded-1 border-info bg-white">
                    {assesment.status}
                  </div>
                  {story?.selected_assessment?._path?.segments?.[1] ===
                    assesment.id && (
                    <span className="ms-4 text-success border border-success ps-2 pe-2">
                      selected assessment
                    </span>
                  )}
                </h6>
              </u>
              <h6>Correct variant:</h6>
              <span>{assesment.correctVariant}</span>

              <hr />

              <h6>Misdirection variant 1:</h6>
              <span>{assesment.misdirectionVariant1}</span>

              <hr />

              <h6>Misdirection variant 2:</h6>
              <span>{assesment.misdirectionVariant2}</span>

              <hr />

              <h6>Explanation:</h6>
              <span>{assesment.explanation}</span>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default View;
