/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useField } from "formik";
import Select from "react-select";

export const MultiSelect = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { options } = props;
  const { error } = meta;
  const { setValue } = helpers;

  const setFormValue = (option) => {
    setValue(option);
  };

  return (
    <div>
      <label className="mt-2" htmlFor={props.id || props.name}>
        {label}
      </label>
      <Select
        isMulti={true}
        options={options}
        {...field}
        {...props}
        onChange={(option) => setFormValue(option)}
      />

      {error ? <div className="alert alert-danger mt-1">{error}</div> : null}
    </div>
  );
};
