/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { createUser } from "../../../states/actions/manageUsersAction";
import CreateUserForm from "./CreateForm";

const CreateUser = ({ show, handleClose, createUser, usersSate, refetch }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateUserForm
          handleClose={handleClose}
          usersState={usersSate}
          createUser={createUser}
          refetch={refetch}
        />
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  usersSate: state.users,
});
export default connect(mapStateToProps, { createUser })(CreateUser);
