/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import TopicService from "../../../../services/TopicService";
import Helpers from "../../../../utils/helpers";
import { Error } from "../../../form-fields/Error";
import EditTopicForm from "./EditForm";

const EditTopic = ({ show, topic, handleClose, authors, invalidate }) => {
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => TopicService.update(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(invalidate);
      handleClose();
    },
    onError: (error) => {
      setError(Helpers.errorCreator(error));
    },
  });

  const handleSubmit = (formData) => {
    const data = { ...formData };
    mutation.mutate({ id: topic.id, data: data });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Topic</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} />
        <EditTopicForm
          topic={topic}
          authors={authors}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditTopic;
