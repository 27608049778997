/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ROLES, userIs } from "../../../constants/Roles";
import Helpers from "../../../utils/helpers";
import { MySelect } from "../../form-fields/Select";
import { MyTextArea } from "../../form-fields/TextArea";
import { MyTextInput } from "../../form-fields/TextInput";

const UpdateAssesmentForm = ({
  handleClose,
  handleSubmit,
  isLoading,
  assesment,
  authors,
  story,
}) => {
  const user = Helpers.getUser();
  const options = authors?.map((writer) => (
    <option key={writer.id} value={writer.id}>
      {writer.name}
    </option>
  ));
  return (
    <div className="col-12 col-md-12">
      <Formik
        initialValues={{
          author: assesment?.author || user?.id,
          correctVariant: assesment?.correctVariant,
          misdirectionVariant1: assesment?.misdirectionVariant1,
          misdirectionVariant2: assesment?.misdirectionVariant2,
          explanation: assesment?.explanation,
          note: assesment?.note,
        }}
        validationSchema={Yup.object({
          author: userIs(ROLES.ADMIN) ? Yup.string().required() : "",
          correctVariant: Yup.string().required(),
          misdirectionVariant1: Yup.string().required(),
          misdirectionVariant2: Yup.string().required(),
          explanation: Yup.string().required(),
        })}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {(formik) => (
          <Form style={{ width: "100%" }}>
            <div className="" style={{ width: "100%" }}>
              {userIs(ROLES.ADMIN) && (
                <MySelect
                  name="author"
                  label="Author"
                  plasceholder="Select author"
                  className="form-select"
                >
                  <option value={user.id} key={user.id}>
                    {user.name}
                  </option>
                  {options}
                </MySelect>
              )}

              <div className="card bg-light mt-2  p-4 border rounded">
                {story?.body}
              </div>
              <MyTextInput
                name="correctVariant"
                label="Correct variant"
                placeholder="Correct situation interpretation"
                className="form-control"
                style={{ width: "100%" }}
              />
              <MyTextInput
                name="misdirectionVariant1"
                label="Misdirection variant 1"
                placeholder="Patient's imprecise/incorrect situation interpretation"
                className="form-control"
                style={{ width: "100%" }}
              />
              <MyTextInput
                name="misdirectionVariant2"
                label="Misdirection variant 2"
                placeholder="Patient's imprecise/incorrect situation interpretation"
                className="form-control"
                style={{ width: "100%" }}
              />
              <MyTextInput
                name="explanation"
                label="Explanation"
                placeholder="Explain why assessment is correct while potential interpretations are imprecise or incorrect"
                className="form-control"
                style={{ width: "100%" }}
              />
              <MyTextArea
                name="note"
                label="Note"
                rows={4}
                placeholder="Write note"
                className="form-control"
                style={{ width: "100%" }}
              />
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-outline-primary px-5 mt-3"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-outline-primary px-5 mt-3"
                  disabled={formik.isSubmitting || !formik.isValid || isLoading}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateAssesmentForm;
