/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import Loader from "../components/utils/Loader";
import { STORY_STATUS_KEY } from "../constants/StoryStatus";
import UserService from "../services/UserService";

export default function Writers() {
  const { isLoading, data } = useQuery(["writers"], () =>
    UserService.fetchAuthors()
  );
  const authors = data?.data;

  const zeroStats = React.useMemo(
    () => ({
      [STORY_STATUS_KEY.review]: 0,
      [STORY_STATUS_KEY.rework]: 0,
      [STORY_STATUS_KEY.finished]: 0,
      [STORY_STATUS_KEY.abandoned]: 0,
    }),
    []
  );

  const totalStats = React.useMemo(
    () =>
      authors
        ?.map((writer) => writer?.storyStats)
        ?.reduce(
          (a, b) => ({
            [STORY_STATUS_KEY.review]:
              a[STORY_STATUS_KEY.review] + b[STORY_STATUS_KEY.review],
            [STORY_STATUS_KEY.rework]:
              a[STORY_STATUS_KEY.rework] + b[STORY_STATUS_KEY.rework],
            [STORY_STATUS_KEY.finished]:
              a[STORY_STATUS_KEY.finished] + b[STORY_STATUS_KEY.finished],
            [STORY_STATUS_KEY.abandoned]:
              a[STORY_STATUS_KEY.abandoned] + b[STORY_STATUS_KEY.abandoned],
          }),
          zeroStats
        ) ?? zeroStats,
    [authors, zeroStats]
  );

  const tableData = React.useMemo(
    () =>
      authors?.map((writer) => {
        return {
          name: writer.name,
          id: writer.id,
          status: writer.status,
          ...zeroStats,
          ...writer?.storyStats,
        };
      }) ?? [],
    [authors, zeroStats]
  );

  const tableDataColumn = React.useMemo(() => {
    const headerTemplate = (status) => ({
      Header: `${status} (${totalStats[status]})`,
      accessor: status,
      sortType: "basic",
      Cell: (data) => (
        <Link
          to={`/internal/stories?author=${data.cell.row.original.id}&status=${status}`}
          className="label label-default"
        >
          {data.value}
        </Link>
      ),
    });
    return [
      {
        Header: "User",
        accessor: "name",
        Cell: (data) => (
          <Link
            to={`/internal/profile/${data.cell.row.original.id}`}
            className="user-link ms-2"
          >
            {(data.cell.row.original.status === "DEACTIVATED" && (
              <strike>{data.value}</strike>
            )) ||
              data.value}
          </Link>
        ),
      },
      headerTemplate(STORY_STATUS_KEY.review),
      headerTemplate(STORY_STATUS_KEY.rework),
      headerTemplate(STORY_STATUS_KEY.finished),
      headerTemplate(STORY_STATUS_KEY.abandoned),
    ];
  }, [totalStats]);

  const tableInstance = useTable(
    { columns: tableDataColumn, data: tableData },
    useSortBy
  );
  const { getTableProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <Loader show={isLoading} />
      <div className="container bootstrap snippets bootdey mt-4 ">
        <div className="row  d-flex justify-content-center">
          <div className="col-lg-8">
            <div className="main-box no-header clearfix">
              <div className="main-box-body clearfix">
                <div className="table-responsive">
                  {authors && (
                    <table className="table user-list" {...getTableProps()}>
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                className="text-center"
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                <span> {column.render("Header")} </span>
                                <span>
                                  {column.isSorted &&
                                    ((column.isSortedDesc && " ▼") || " ▲")}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {rows
                          .map((row) => prepareRow(row) || row)
                          .map((row) => (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td {...cell.getCellProps()} align="center">
                                  {cell.render("Cell")}
                                </td>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
