/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router";
import seedrandom from "seedrandom";
import { ROLES, userIs } from "../../constants/Roles";
import { STORY_STATUS } from "../../constants/StoryStatus";
import CollectionService from "../../services/CollectionService";
import StoryService from "../../services/StoryService";
import TopicService from "../../services/TopicService";
import UserService from "../../services/UserService";
import Helpers from "../../utils/helpers";
import { MySelect } from "../form-fields/Select";
import ViewStory from "./view/View";

const queryString = require("query-string");
const StoriesList = ({
  status,
  sortOrder,
  author,
  assessed,
  topic,
  withSearch,
  collection,
}) => {
  const [collections, setCollections] = useState([]);
  const [storyQuery, setQuery] = useState({});
  const [authors, setAuthors] = useState([]);
  const [topics, setTopic] = useState([]);
  const storyOptions = STORY_STATUS.map((i) => (
    <option value={i.value} label={i.label}></option>
  ));

  const authorOptions = authors.map((i) => (
    <option value={i.id} label={i.name}></option>
  ));

  const topicOptions = topics.map((i) => (
    <option value={i.id} label={i.title}></option>
  ));
  const collectionOptions = collections.map((i) => (
    <option value={i.id} label={i.title}></option>
  ));
  status = storyQuery.status ? storyQuery.status : status;
  author = storyQuery.author ? storyQuery.author : author;
  topic = storyQuery.topic ? storyQuery.topic : topic;
  collection = storyQuery.collection ? storyQuery.collection : collection;
  assessed = storyQuery.assessed ? storyQuery.assessed : assessed;
  const fetchAuthors = () => {
    UserService.fetchAuthors().then((res) => {
      setAuthors(res.data);
    });
  };

  const fetchTopics = () => {
    TopicService.fetchAll().then((res) => {
      setTopic(res.data);
    });
  };

  const fetchCollections = () => {
    CollectionService.fetchAll().then((res) => {
      setCollections(res.data);
    });
  };

  useEffect(() => {
    if (withSearch) {
      userIs(ROLES.ADMIN) && fetchAuthors();
      fetchTopics();
      userIs(ROLES.ADMIN) && fetchCollections();
    }
  }, []);

  useEffect(() => {});

  const fetchAll = ({ pageParam }) => {
    if (userIs(ROLES.PSYCHOLOGIST)) {
      return StoryService.fetchPsychologistStory({
        startAfter: pageParam,
        status: status,
        sortOrder: sortOrder,
        author: author,
        topic: topic,
        assessed: assessed,
        collection: collection,
        limit: 50,
      });
    } else
      return StoryService.fetchActive({
        startAfter: pageParam,
        status: status,
        sortOrder: sortOrder,
        author: author,
        topic: topic,
        assessed: assessed,
        collection: collection,
      });
  };
  const history = useHistory();
  const { isLoading, isFetching, data, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      [
        "filteredStories",
        status,
        author,
        topic,
        sortOrder,
        collection,
        assessed,
      ],
      fetchAll,
      {
        keepPreviousData: true,
        onSuccess: (response) => {
          let storyArray = [];
          response.pages.map((i) =>
            i.data?.stories?.map((j) => storyArray.push(j))
          );
        },
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage?.data?.stories?.length) return undefined;
          else
            return lastPage.data.stories[lastPage.data.stories.length - 1].id;
        },
        getPreviousPageParam: (lastPage, pages) => {
          if (!lastPage?.data?.length) return null;
          else
            return lastPage.data.stories[lastPage.data.stories.length - 1].id;
        },
      }
    );
  let storiesReordering = (stories) => stories;
  if (userIs(ROLES.PSYCHOLOGIST)) {
    const random = seedrandom(Helpers.getUser().id);
    storiesReordering = (stories) => {
      stories = stories ?? [];
      stories = [...stories];
      for (let i = stories.length - 1; i > 0; i--) {
        const index = Math.trunc(random() * (i + 1));
        const story = stories[i];
        stories[i] = stories[index];
        stories[index] = story;
      }
      return stories;
    };
  }
  const stories =
    data?.pages
      ?.flatMap((page) => storiesReordering(page.data?.stories))
      ?.map((story) => <ViewStory key={story.id} story={story} />) ?? [];

  const onSearch = (qry) => {
    let string = queryString.stringify(qry);
    history.replace("/internal/stories?" + string);
  };

  useEffect(() => {
    let qry = queryString.parse(history.location.search);
    setQuery(qry);
  }, [history.location.search]);

  return (
    <>
      {withSearch && (
        <div className="d-flex justify-content-end">
          <Formik
            initialValues={{
              status: status,
              author: author,
              topic: topic,
              assessed: assessed,
              collection: collection,
            }}
            enableReinitialize
            onSubmit={async (values) => {
              onSearch(values);
              // updateUser(values);
            }}
          >
            {(formikProps) => (
              <Form className="d-flex flex-row w-100 justify-content-end">
                <div className="w-100 me-4">
                  <MySelect
                    name="status"
                    label="Status"
                    placeholder="User Status"
                    className="form-control "
                  >
                    <option value="" key="">
                      All
                    </option>
                    {storyOptions}
                  </MySelect>
                </div>
                {userIs(ROLES.ADMIN) && (
                  <div className="w-100 me-4">
                    <MySelect
                      name="author"
                      label="author"
                      placeholder="User Status"
                      className="form-control"
                    >
                      <option key={"1"} value={""}>
                        {" "}
                        {"All"}
                      </option>
                      {authorOptions}
                    </MySelect>
                  </div>
                )}
                <div className="w-100 me-4">
                  <MySelect
                    name="topic"
                    label="topic"
                    placeholder="User Status"
                    className="form-control"
                  >
                    <option key={"1"} value={""}>
                      {" "}
                      {"All"}
                    </option>
                    {topicOptions}
                  </MySelect>
                </div>
                <div className="w-100 me-4">
                  <MySelect
                    name="collection"
                    label="collection"
                    placeholder="User Status"
                    className="form-control"
                  >
                    <option key={"1"} value={""}>
                      {" "}
                      {"All"}
                    </option>
                    <option key={"2"} value={"null"}>
                      {" "}
                      {"None"}
                    </option>
                    {collectionOptions}
                  </MySelect>
                </div>
                <div className="w-100 me-4">
                  <MySelect
                    name="assessed"
                    label="assessed"
                    placeholder="User Status"
                    className="form-control"
                  >
                    <option key={"1"} value={""}>
                      {" "}
                      {"All"}
                    </option>
                    <option key={"2"} value={"true"}>
                      {" "}
                      {"Yes"}
                    </option>
                    <option key={"3"} value={"false"}>
                      {" "}
                      {"No"}
                    </option>
                  </MySelect>
                </div>
                <div className="d-flex justify-content-between mt-3 h-20">
                  <button
                    disabled={formikProps.isSubmitting || !formikProps.isValid}
                    className="btn btn-outline-primary px-5"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      <h6>
        {isLoading || (
          <>
            Showing {stories.length}
            {(hasNextPage && ", but could be more.") || ", and that is all."}
          </>
        )}
      </h6>
      <div className="my-3 list-group">
        <InfiniteScroll
          dataLength={stories.length} //This is important field to render the next data
          next={() => fetchNextPage()}
          hasMore={hasNextPage}
          loading={isLoading}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              {isLoading || isFetching ? (
                <b>Loading...</b>
              ) : (
                <b>
                  No more stories available. Already showing you{" "}
                  {stories.length} stories.
                </b>
              )}
            </p>
          }
        >
          {stories}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default StoriesList;
