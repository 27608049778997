/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Helpers from "../../../utils/helpers";
const StoryStatusModal = (props) => {
  const [currentIndex, setIndex] = useState(
    props?.storyPreviousValue?.length - 1
  );
  useEffect(() => {
    setIndex(props?.storyPreviousValue?.length - 1);
  }, [props?.storyPreviousValue]);

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      dialogClassName="modal-90w"
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center justify-content-between w-100 pe-4">
          Latest Changes{" "}
          <div style={{ fontSize: 30 }} className="d-flex align-items-center">
            <button
              style={{ background: "none", border: "none" }}
              disabled={currentIndex === 0}
              onClick={() => setIndex(currentIndex - 1)}
            >
              <ArrowLeft className="me-4 " />
            </button>

            <button
              style={{ background: "none", border: "none" }}
              disabled={currentIndex >= props?.storyPreviousValue?.length - 1}
              onClick={() => setIndex(currentIndex + 1)}
            >
              <ArrowRight className="me-4" />
            </button>

            <span style={{ fontSize: 18 }}>
              {" "}
              by{" "}
              <Link
                className="text-decoration-none"
                to={`/internal/profile/${props.storyPreviousValue?.[currentIndex]?.author.id}`}
              >
                {props.storyPreviousValue?.[currentIndex]?.author.name}
              </Link>
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Helpers.convertMultiLine(
          props
            .StoryDiff(
              props.storyPreviousValue?.[currentIndex]?.oldValue,
              props.storyPreviousValue?.[currentIndex]?.newValue
            )
            .map((i) => {
              if (i.kind === "=") {
                return i.text;
              }
              if (i.kind === "+") {
                return <span style={{ background: "#ABF2BC" }}>{i.text}</span>;
              }
              if (i.kind === "-") {
                return (
                  <span style={{ background: "rgba(255,129,130,0.4)" }}>
                    {i.text}
                  </span>
                );
              }
            })
        )}
      </Modal.Body>
    </Modal>
  );
};

export default StoryStatusModal;
