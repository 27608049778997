/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import TopicsList from "../components/topics/List";
import Loader from "../components/utils/Loader";
import TopicService from "../services/TopicService";
import UserService from "../services/UserService";
import Helpers from "../utils/helpers";
import { ROLES } from "./../constants/Roles";
const Topics = () => {
  const [topics, setTopics] = useState([]);
  const [error, setError] = useState(null);
  const [archived, setArchived] = useState(false);
  const [user, setUser] = useState(null);
  const [authors, setAuthors] = useState([]);
  useEffect(() => {
    const user = Helpers.getUser();
    setUser(user);
    if (ROLES.ADMIN === user?.role) fetchAuthors();
  }, []);
  const fetchAll = ({ queryKey }) => {
    const { archived } = queryKey[1];
    return TopicService.fetchAll(archived);
  };

  const { isLoading, isFetching } = useQuery(
    ["topics", { archived }],
    fetchAll,
    {
      onSuccess: (response) => {
        setTopics(response.data);
      },
      onError: (error) => {
        setError(Helpers.errorCreator(error));
      },
    }
  );

  const fetchAuthors = () => {
    UserService.fetchAuthors().then((res) => {
      setAuthors(res.data);
    });
  };

  return (
    <>
      <Loader show={isLoading || isFetching} />
      <div className="container my-5">
        <TopicsList
          topics={topics}
          authors={authors}
          user={user}
          error={error}
          setArchived={setArchived}
        />
      </div>{" "}
    </>
  );
};

export default Topics;
