/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Fragment, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useParams } from "react-router-dom";
import { ROLES, userIs } from "../../../constants/Roles";
import { STORY_STATUS_KEY } from "../../../constants/StoryStatus";
import AssesmentService from "../../../services/AssesmentService";
import Helpers from "../../../utils/helpers";
import { Error } from "../../form-fields/Error";
import Loader from "../../utils/Loader";
import SubmitStory from "./SubmitAssesment.jsx";

const View = () => {
  const [story, setStory] = useState(null);
  const [error, setError] = useState();
  const [isAssessmentDone, setAssessmentDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assesments, setAssesments] = useState([]);
  const [showSubmitVersionModal, setShowSubmitModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const params = useParams();
  const queryClient = useQueryClient();
  const storyId = params.id;
  const handleError = (error) => {
    setError(Helpers.errorCreator(error));
  };

  const fetchStory = () => {
    return AssesmentService.fetchAll(storyId);
  };

  const { isLoading, isFetching } = useQuery(
    ["assesments", { storyId: storyId }],
    fetchStory,
    {
      onSuccess: (response) => {
        setStory(response.data.story);
        setAssesments(response.data.assesments);
        setAssessmentDone(response.data.isAssessmentDone);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const selectAssesment = (id) => {
    setLoading(true);
    return AssesmentService.selectAssesment(id)
      .then((res) => {
        queryClient.invalidateQueries(["assesments", { storyId: storyId }]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const unselectAssesment = (id) => {
    setLoading(true);
    return AssesmentService.unselectAssesment(id)
      .then((res) => {
        queryClient.invalidateQueries(["assesments", { storyId: storyId }]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const hideCreateTopicModal = () => {
    setShowSubmitModal(false);
  };
  const openCreateModal = () => {
    setShowSubmitModal(true);
  };
  return (
    <Fragment>
      <Loader show={isLoading || isFetching} />
      <div className="container mt-5">
        <Error error={error} />
        {story ? (
          <div className="card bg-light  p-4 border rounded">
            <div
              className="details"
              style={{ display: "flex", padding: "5px 20px" }}
            >
              <h4>
                {userIs(ROLES.ADMIN) && (
                  <Link
                    className="text-decoration-none"
                    to={`/internal/stories/${story.id}`}
                  >
                    {story.title}
                  </Link>
                )}
                {userIs(ROLES.WRITER) && story.title}
                {userIs(ROLES.PSYCHOLOGIST) &&
                  story.body.substring(0, 35).trim().replace(/\W+$/, "") + "…"}
              </h4>
              {userIs(ROLES.ADMIN, ROLES.WRITER) && (
                <span
                  className="text-bold small text-secondary text-link"
                  style={{ marginLeft: "15px", marginTop: "6px" }}
                >
                  {Helpers.convertTime(story.updated_at)}
                </span>
              )}
              {story.author ? (
                <span style={{ marginLeft: "15px", marginTop: "8px" }}>
                  <h6>
                    {userIs(ROLES.ADMIN) && (
                      <Link
                        className="text-decoration-none"
                        to={`/internal/profile/${story.author.id}`}
                      >
                        {` ${story.author.name}`}
                      </Link>
                    )}
                  </h6>
                </span>
              ) : (
                ""
              )}
            </div>

            {userIs(ROLES.ADMIN) && (
              <h6 style={{ padding: "0px 20px" }}>
                on{" "}
                <Link
                  className="text-decoration-none"
                  to={`/internal/topics/${story.topic.id}`}
                >
                  {story?.topic?.title}
                </Link>
              </h6>
            )}
            <div className="d-flex m-2  align-items-center">
              {(!isAssessmentDone || userIs(ROLES.ADMIN)) &&
                !story.selected_assessment && (
                  <span className="btn btn-primary" onClick={openCreateModal}>
                    Create assessment
                  </span>
                )}
            </div>
            <p style={{ padding: "0px 20px" }}>
              {Helpers.convertMultiLine(story.body)}
            </p>

            {assesments.map((i) => {
              return (
                <div className="card   p-4 border rounded">
                  <h6 className="d-flex">
                    <u>
                      <Link
                        className="text-decoration-none"
                        to={`/internal/stories/${story.id}/assesments/${i.id}`}
                      >
                        Assessment by {` ${i.author.name}`}
                      </Link>
                    </u>
                    <div
                      style={{ height: 28 }}
                      className="status ms-2 text-lowercase border-2 text-info text-body border px-2 rounded-1 border-info bg-white"
                    >
                      {i.status}
                    </div>

                    {userIs(ROLES.ADMIN) &&
                      story?.selected_assessment?._path?.segments?.[1] ===
                        i.id && (
                        <span className="ms-4 text-success border border-success ps-2 pe-2">
                          selected assessment
                        </span>
                      )}
                    <>
                      {userIs(ROLES.ADMIN) &&
                        story?.selected_assessment?._path?.segments?.[1] ===
                          i.id && (
                          <button
                            className="btn btn-primary ms-4"
                            onClick={() => unselectAssesment(i.id)}
                          >
                            Unselect assessment
                          </button>
                        )}
                      {userIs(ROLES.ADMIN) &&
                        i.status === STORY_STATUS_KEY.finished &&
                        story?.selected_assessment?._path?.segments?.[1] !==
                          i.id && (
                          <button
                            className="btn btn-primary ms-4"
                            onClick={() => selectAssesment(i.id)}
                          >
                            Select assessment
                          </button>
                        )}
                    </>
                  </h6>

                  <h6>Correct variant:</h6>
                  <span>{i.correctVariant}</span>

                  <hr />

                  <h6>Misdirection variant 1:</h6>
                  <span>{i.misdirectionVariant1}</span>

                  <hr />

                  <h6>Misdirection variant 2:</h6>
                  <span>{i.misdirectionVariant2}</span>

                  <hr />

                  <h6>Explanation:</h6>
                  <span>{i.explanation}</span>
                  <hr />
                  <h6>Note:</h6>
                  <span>{i.note}</span>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>

      <SubmitStory
        show={showSubmitVersionModal}
        handleClose={hideCreateTopicModal}
        story={story}
      />
    </Fragment>
  );
};

export default View;
