/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import moment from "moment";
import { Link } from "react-router-dom";
import Helpers from "../../utils/helpers";

const Collection = ({ collection, openEditModal }) => {
  let publishedDate =
    collection?.published_at &&
    new Date(
      collection?.published_at._seconds * 1000 +
        collection?.published_at._nanoseconds / 1000000
    );
  return (
    <div className="card my-2">
      <div className="card-header d-flex justify-content-between">
        <div className="d-flex gap-3">
          <div>
            <button
              className="btn btn-outline-secondary py-0"
              onClick={() => openEditModal(collection)}
            >
              Edit
            </button>
            <span className="ms-2 text-muted">
              {collection?.stories_total} stories
            </span>
            <span className="ms-2 text-muted">
              {collection?.stories_assessed} assessed
            </span>
            <span className="ms-2 text-muted">
              {collection?.stories_filled} filled
            </span>
            <span className="ms-2 text-muted">
              {collection?.stories_could_be_filled_by_review} can be filled by
              review
            </span>
            <span className="ms-2 text-muted">
              {collection?.stories_could_be_filled_by_selecting} can be filled
              by selecting
            </span>
          </div>
        </div>
        <div className="details">
          <span className="text-bold small text-secondary text-link">
            {" "}
            {Helpers.convertTime(collection.created_at)}
          </span>
        </div>
      </div>
      <div className="card-body">
        <h5 className="card-title m-0">
          <Link
            className="text-decoration-none text-1-line"
            to={"./collection/" + collection.id}
          >
            {collection.title}
          </Link>
        </h5>
        <p className="card-text text-3-line mt-2">{collection.description}</p>
        {(publishedDate < new Date() && publishedDate && (
          <small className="text-success">
            Published on :{" "}
            {moment(
              new Date(
                collection.published_at._seconds * 1000 +
                  collection.published_at._nanoseconds / 1000000
              )
            ).format("DD MMM yyyy hh:mm")}
          </small>
        )) ||
          (publishedDate > new Date() && publishedDate && (
            <small className="text-success">
              Will publish on :{" "}
              {moment(
                new Date(
                  collection.published_at._seconds * 1000 +
                    collection.published_at._nanoseconds / 1000000
                )
              ).format("DD MMM yyyy hh:mm")}
            </small>
          )) || <small className="text-danger">Not Published</small>}
      </div>
    </div>
  );
};
export default Collection;
