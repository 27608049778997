/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import Helpers from "../../../utils/helpers";

const ViewComment = ({ comment }) => {
  return (
    <div className="card my-2">
      <div className="card-body">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex  " }}>
            <p>
              <b>by {comment.author.name}</b>
            </p>
          </div>
          <span className=" small text-secondary text-link">
            {Helpers.convertTime(comment.created_at)}
          </span>
        </div>
        <p> {Helpers.convertMultiLine(comment.body)} </p>
      </div>
    </div>
  );
};

export default ViewComment;
