/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import AssesmentService from "../../../services/AssesmentService";
import Helpers from "../../../utils/helpers";
import { Error } from "../../form-fields/Error";
import UpdateStoryForm from "./UpdateAssesmentForm";

const SubmitAssesment = (props) => {
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (formData) => AssesmentService.update(props.assesmentId, { ...formData }),
    {
      onSuccess: (data) => {
        props.handleClose();
        queryClient.invalidateQueries("assesments");
      },
      onError: (error) => {
        setError(Helpers.errorCreator(error));
      },
    }
  );

  const handleSubmit = async (formData) => {
    const data = { ...formData };

    mutation.mutate(data);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      dialogClassName="modal-90w"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Submit new version</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error error={error} />
        <UpdateStoryForm
          handleSubmit={handleSubmit}
          handleClose={props.handleClose}
          isCreating={mutation.isLoading}
          assesment={props.assesment}
          story={props.story}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SubmitAssesment;
