/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { useField } from "formik";

export const MySwitch = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div>
      {label && (
        <label className="mt-2" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckChecked"
          checked={field.value}
          defaultChecked={false}
          {...field}
        />
        <label class="form-check-label" for="flexSwitchCheckChecked">
          restricted
        </label>
      </div>
      {meta.touched && meta.error ? (
        <div className="alert alert-danger mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};
