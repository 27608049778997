/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import {
  BAD_REQUEST,
  FORBIDDEN,
  NOT_ALLOWED,
  NOT_FOUND,
  SERVER_ERROR,
} from "../../constants/ErrorStatusCodes";

export const errorActionCreator = (errorType, error) => {
  const err = error.error;
  if (err && err.data) {
    var { message } = err.data;
    var { status } = err;
  }

  console.error(errorType, error);

  if (!message) {
    if (status === NOT_FOUND) {
      message = "Not Found.";
    } else if (status === BAD_REQUEST) {
      message = "Bad Request.";
    } else if (status === NOT_ALLOWED) {
      message = "Not Allowed.";
    } else if (status === FORBIDDEN) {
      message = "Fobidden.";
    } else if (status === SERVER_ERROR) {
      message = "Something went wrong, try again.";
    } else {
      message = "Something went wrong";
    }
  }

  return {
    type: errorType,
    error: !!error,
    message: message,
  };
};
