/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MyTextInput } from "../../form-fields/TextInput";

const UpdateName = ({ user, updateProfile }) => {
  return (
    <Formik
      initialValues={{
        name: user.name,
      }}
      validationSchema={Yup.object({
        name: Yup.string(),
      })}
      onSubmit={async (values) => {
        updateProfile(values);
      }}
    >
      {(formikProps) => (
        <Form>
          <div className="d-flex gap-3">
            <div className="flex-grow-1">
              <MyTextInput
                className="form-control flex-grow-0"
                name="name"
                type="text"
                label=""
                placeholder="Full name"
                disabled
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateName;
