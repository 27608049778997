/*
 Copyright ©. All Rights Reserved. Confidential and proprietary.
 Taras Mazepa. Contact address: taras@maze.pa .
 */
import AssessmentList from "../components/stories/assesment/FilterView";
import { ROLES, userIs } from "../constants/Roles";
import Helpers from "../utils/helpers";

const user = Helpers.getUser();
const Assessments = () => {
  return (
    <div className="container my-5">
      <div className="d-flex justify-content-between">
        <h1>Assessments</h1>
      </div>
      <AssessmentList
        sortOrder={"asc"}
        withSearch
        author={userIs(ROLES.ADMIN) ? undefined : user.id}
      />
    </div>
  );
};

export default Assessments;
